import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
import "@bryntum/core-thin/lib/localization/Es.js";
const emptyString = new String();
const locale = {
  localeName: "Es",
  localeDesc: "Espa\xF1ol",
  localeCode: "es",
  ColumnPicker: {
    column: "Columna",
    columnsMenu: "Columnas",
    hideColumn: "Ocultar columna",
    hideColumnShort: "Ocultar",
    newColumns: "Nuevas columnas"
  },
  Filter: {
    applyFilter: "Aplicar filtro",
    filter: "Filtro",
    editFilter: "Editar filtro",
    on: "Activo",
    before: "Antes",
    after: "Despu\xE9s",
    equals: "Equivale a",
    lessThan: "Inferior a",
    moreThan: "Superior a",
    removeFilter: "Quitar filtro",
    disableFilter: "Deshabilitar filtro"
  },
  FilterBar: {
    enableFilterBar: "Mostrar barra de filtro",
    disableFilterBar: "Ocultar barra de filtro"
  },
  Group: {
    group: "Agrupar",
    groupAscending: "Agrupar ascendentes",
    groupDescending: "Agrupar descendentes",
    groupAscendingShort: "Ascendentes",
    groupDescendingShort: "Descendentes",
    stopGrouping: "Dejar de agrupar",
    stopGroupingShort: "Dejar"
  },
  HeaderMenu: {
    moveBefore: text => `Mover delante de "${text}"`,
    moveAfter: text => `Mover detr\xE1s de "${text}"`,
    collapseColumn: "Comprimir columna",
    expandColumn: "Expandir columna"
  },
  ColumnRename: {
    rename: "Renombrar"
  },
  MergeCells: {
    mergeCells: "Combinar celdas",
    menuTooltip: "Combinar celdas con el mismo valor al ordenarlas por esta columna"
  },
  Search: {
    searchForValue: "Buscar valor"
  },
  Sort: {
    sort: "Ordenar",
    sortAscending: "Orden ascendente",
    sortDescending: "Orden descendente",
    multiSort: "Orden m\xFAltiple",
    removeSorter: "Eliminar criterio de orden",
    addSortAscending: "A\xF1adir criterio ascendente",
    addSortDescending: "A\xF1adir criterio ascendente",
    toggleSortAscending: "Cambiar a ascendente",
    toggleSortDescending: "Cambiar a descendente",
    sortAscendingShort: "Ascendente",
    sortDescendingShort: "Descendente",
    removeSorterShort: "Eliminar",
    addSortAscendingShort: "+ Ascendente",
    addSortDescendingShort: "+ Descendente"
  },
  Split: {
    split: "Dividir",
    unsplit: "No dividir",
    horizontally: "Horizontalmente",
    vertically: "Verticalmente",
    both: "Ambos"
  },
  LockRows: {
    lockRow: "Bloquear fila",
    unlockRow: "Desbloquear fila"
  },
  Column: {
    columnLabel: column => `${column.text ? `${column.text} columna. ` : ""}ESPACIO para el men\xFA contextual${column.sortable ? ", INTRO para ordenar" : ""}`,
    cellLabel: emptyString
  },
  Checkbox: {
    toggleRowSelect: "Alternar selecci\xF3n de filas",
    toggleSelection: "Alternar selecci\xF3n de todo el conjunto de datos"
  },
  RatingColumn: {
    cellLabel: column => {
      var _a;
      return `${column.text ? column.text : ""} ${((_a = column.location) == null ? void 0 : _a.record) ? `clasificaci\xF3n : ${column.location.record.get(column.field) || 0}` : ""}`;
    }
  },
  GridBase: {
    loadFailedMessage: "Fallo al cargar los datos",
    syncFailedMessage: "Fallo al sincronizar los datos",
    unspecifiedFailure: "Fallo no especificado",
    networkFailure: "Error de red",
    parseFailure: "Fallo al analizar la respuesta del servidor",
    serverResponse: "Respuesta del servidor:",
    noRows: "Sin registros que mostrar",
    moveColumnLeft: "Mover a la secci\xF3n izquierda",
    moveColumnRight: "Mover a la secci\xF3n derecha",
    moveColumnTo: region => `Mover columna a ${region}`
  },
  CellMenu: {
    removeRow: "Eliminar"
  },
  RowCopyPaste: {
    copyRecord: "Copiar",
    cutRecord: "Cortar",
    pasteRecord: "Pegar",
    rows: "filas",
    row: "fila"
  },
  CellCopyPaste: {
    copy: "Copiar",
    cut: "Cortar",
    paste: "Pegar"
  },
  PdfExport: {
    "Waiting for response from server": "Esperando respuesta del servidor...",
    "Export failed": "Fallo al exportar",
    "Server error": "Error del servidor",
    "Generating pages": "Generando p\xE1ginas...",
    "Click to abort": "Cancelar"
  },
  ExportDialog: {
    width: "40em",
    labelWidth: "12em",
    exportSettings: "Exportar ajustes",
    export: "Exportar",
    printSettings: "Configuraci\xF3n de impresi\xF3n",
    print: "Imprimir",
    exporterType: "Controlar la paginaci\xF3n",
    cancel: "Cancelar",
    fileFormat: "Formato de archivo",
    rows: "Filas",
    alignRows: "Alinear filas",
    columns: "Columnas",
    paperFormat: "Formato de papel",
    orientation: "Orientaci\xF3n",
    repeatHeader: "Repetir encabezado"
  },
  ExportRowsCombo: {
    all: "Todas las filas",
    visible: "Filas visibles"
  },
  ExportOrientationCombo: {
    portrait: "Retrato",
    landscape: "Panor\xE1mica"
  },
  SinglePageExporter: {
    singlepage: "Una sola p\xE1gina"
  },
  MultiPageExporter: {
    multipage: "P\xE1ginas m\xFAltiples",
    exportingPage: ({
      currentPage,
      totalPages
    }) => `Exportando p\xE1gina ${currentPage}/${totalPages}`
  },
  MultiPageVerticalExporter: {
    multipagevertical: "P\xE1ginas m\xFAltiples (vertical)",
    exportingPage: ({
      currentPage,
      totalPages
    }) => `Exportando p\xE1gina  ${currentPage}/${totalPages}`
  },
  RowExpander: {
    loading: "Cargando",
    expand: "Expandir",
    collapse: "Contrar"
  },
  TreeGroup: {
    group: "Agrupar por",
    stopGrouping: "Detener agrupaci\xF3n",
    stopGroupingThisColumn: "Desagrupar esta columna"
  }
};
var Es_default = LocaleHelper.publishLocale(locale);
export { Es_default as default };