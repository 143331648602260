import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
import "@bryntum/engine-thin/lib/localization/Es.js";
import "@bryntum/scheduler-thin/lib/localization/Es.js";
const locale = {
  localeName: "Es",
  localeDesc: "Espa\xF1ol",
  localeCode: "es",
  ConstraintTypePicker: {
    none: "Ninguno",
    assoonaspossible: "Lo antes posible",
    aslateaspossible: "Lo m\xE1s tarde posible",
    muststarton: "Debe empezar el",
    mustfinishon: "Debe terminar el",
    startnoearlierthan: "Empezar no antes del",
    startnolaterthan: "Empezar no despu\xE9s del",
    finishnoearlierthan: "Terminar no antes del",
    finishnolaterthan: "Terminar no despu\xE9s del"
  },
  SchedulingDirectionPicker: {
    Forward: "Adelante",
    Backward: "Atr\xE1s",
    inheritedFrom: "Heredado de",
    enforcedBy: "Impuesto por"
  },
  CalendarField: {
    "Default calendar": "Calendario predeterminado",
    editCalendar: "Editar calendario"
  },
  TaskEditorBase: {
    Information: "Informaci\xF3n",
    Save: "Guardar",
    Cancel: "Cancelar",
    Delete: "Eliminar",
    calculateMask: "Calculando...",
    saveError: "No se puede guardar, corrija antes los errores",
    repeatingInfo: "Viendo una evento que se repite",
    editRepeating: "Editar"
  },
  TaskEdit: {
    editEvent: "Editar evento",
    ConfirmDeletionTitle: "Confirmar eliminaci\xF3n",
    ConfirmDeletionMessage: "\xBFSeguro que desea eliminar el evento?"
  },
  GanttTaskEditor: {
    editorWidth: "44em"
  },
  SchedulerTaskEditor: {
    editorWidth: "32em"
  },
  SchedulerGeneralTab: {
    labelWidth: "6em",
    General: "General",
    Name: "Nombre",
    Resources: "Recursos",
    "% complete": "% realizado",
    Duration: "Duraci\xF3n",
    Start: "Inicio",
    Finish: "Finalizaci\xF3n",
    Effort: "Trabajo",
    Preamble: "Pr\xF3logo",
    Postamble: "Ep\xEDlogo"
  },
  GeneralTab: {
    labelWidth: "6.5em",
    General: "General",
    Name: "Nombre",
    "% complete": "% realizado",
    Duration: "Duraci\xF3n",
    Start: "Inicio",
    Finish: "Finalizaci\xF3n",
    Effort: "Trabajo",
    Dates: "Fechas"
  },
  SchedulerAdvancedTab: {
    labelWidth: "13em",
    Advanced: "Avanzado",
    Calendar: "Calendario",
    "Scheduling mode": "Modo de programaci\xF3n",
    "Effort driven": "Trabajo invertido",
    "Manually scheduled": "Programado manualmente",
    "Constraint type": "Tipo de restricci\xF3n",
    "Constraint date": "Fecha de la restricci\xF3n",
    Inactive: "Inactivo",
    "Ignore resource calendar": "Ignorar calendario de recursos"
  },
  CalendarEditorDatePicker: {
    addException: "A\xF1adir excepci\xF3n",
    addWeek: "Agregar semana"
  },
  CalendarEditorExceptionTab: {
    addException: "A\xF1adir excepci\xF3n",
    removeException: "Eliminar excepci\xF3n",
    noRows: "No se agregaron excepciones"
  },
  CalendarEditorWeekTab: {
    addWeek: "Agregar semana",
    removeWeek: "Eliminar la semana"
  },
  CalendarEditor: {
    daysAreWorkingByDefault: "Los d\xEDas funcionan de forma predeterminada",
    workingTimeCalendar: "Calendario de tiempo de trabajo",
    exceptions: "Excepciones",
    general: "General",
    name: "Nombre",
    parent: "Padre",
    save: "Ahorrar",
    weeks: "Semanas",
    error: "Error",
    inputErrors: "Hay errores de entrada. Por favor, arreglelos antes de guardar sus cambios.",
    delete: "Borrar",
    addCalendar: "Agregar un calendario",
    confirmation: "Confirmaci\xF3n",
    removeCalendarWithChildren: 'El calendario tiene calendarios infantiles. Haga clic en "S\xED" para eliminar el calendario con sus hijos.',
    removeCalendarAndLinks: 'El calendario es utilizado por algunos registros. Haga clic en "S\xED" para desactivarlos y eliminar el calendario.',
    newException: "Nueva excepci\xF3n",
    newWeek: "Nueva semana"
  },
  CalendarEditorDateInfo: {
    isWorking: " est\xE1 trabajando",
    isNotWorking: " no est\xE1 trabajando",
    basedOn: " Residencia en:",
    byDefault: "por defecto",
    rangeTpl: (start, end) => `${start} a ${end}`
  },
  CalendarEditorLegend: {
    workingDay: "D\xEDa de trabajo",
    nonWorkingDay: "D\xEDa de no trabajar"
  },
  AvailabilityRangeError: {
    errorOverlap: "Hay rangos superpuestos",
    errorMissingDate: "Se requieren tiempo de inicio y finalizaci\xF3n",
    errorStartAfterEnd: "La hora de inicio no puede ser despu\xE9s de la hora final"
  },
  CalendarEditorExceptionPanel: {
    name: "Nombre",
    from: "De",
    to: "A",
    is: "Es",
    nonWorking: "No funciona",
    working: "Laboral",
    hours: "Horas",
    repeat: "Repetir",
    repeatEnd: "Repetido final",
    errorMissingDate: "Se requiere un rango de fechas de excepci\xF3n",
    errorStartAfterEnd: '"Desde" La fecha no puede ser despu\xE9s de la fecha "para" ',
    errorStartAndEndRepeatNumberMismatch: "La excepci\xF3n debe comenzar y terminar el mismo n\xFAmero de veces"
  },
  CalendarEditorWeekPanel: {
    name: "Nombre",
    from: "De",
    to: "A",
    days: "D\xEDas",
    copyDay: "D\xEDa de copia",
    pasteDay: "D\xEDa de pasta",
    stopCopying: "Dejar de copiar"
  },
  CalendarEditorAvailabilityRangeContainer: {
    addRange: "Agregar rango",
    removeRange: "Eliminar el rango"
  },
  CalendarEditorWeekGrid: {
    errorStartAfterEnd: '"Desde" La fecha no puede ser despu\xE9s de la fecha "para" ',
    errorNoDefaultWeek: "Se requiere la configuraci\xF3n de la semana",
    errorMultipleDefaultWeeks: "Se especifica m\xE1s de una semana predeterminada",
    errorNoWeekAvailability: "La semana debe proporcionar algo de tiempo de trabajo",
    errorInvalidWeekAvailability: "La semana tiene errores de configuraci\xF3n del tiempo de trabajo",
    noRows: "No se agregan semanas"
  },
  AdvancedTab: {
    labelWidth: "11.5em",
    Advanced: "Avanzado",
    Calendar: "Calendario",
    "Scheduling mode": "Modo de programaci\xF3n",
    "Effort driven": "Trabajo invertido",
    "Manually scheduled": "Programado manualmente",
    "Constraint type": "Tipo de restricci\xF3n",
    "Constraint date": "Fecha de la restricci\xF3n",
    Constraint: "Restricci\xF3n",
    Rollup: "Aplazamiento al resumen",
    Inactive: "Inactivo",
    "Ignore resource calendar": "Ignorar calendario de recursos",
    "Scheduling direction": "Direcci\xF3n de programaci\xF3n",
    projectBorder: "Border de proyectos",
    ignore: "Ignorar",
    honor: "Honor",
    askUser: "Pedirle al usuario"
  },
  DependencyTab: {
    Predecessors: "Anteriores",
    Successors: "Posteriores",
    ID: "DNI",
    Name: "Nombre",
    Type: "Tipo",
    Lag: "Retraso",
    cyclicDependency: "Dependencia c\xEDclica",
    invalidDependency: "Dependencia no v\xE1lida"
  },
  NotesTab: {
    Notes: "Notas"
  },
  ResourceCalendarColumn: {
    calendar: "Calendario"
  },
  ResourcesTab: {
    unitsTpl: ({
      value
    }) => `${value}%`,
    Resources: "Recursos",
    Resource: "Recurso",
    Units: "Unidades"
  },
  RecurrenceTab: {
    title: "Repetir"
  },
  SchedulingModePicker: {
    Normal: "Normal",
    "Fixed Duration": "Duraci\xF3n",
    "Fixed Units": "Unidades fijas",
    "Fixed Effort": "Trabajo fijo"
  },
  ResourceHistogram: {
    barTipInRange: '<b>{resource}</b> {startDate} - {endDate}<br><span class="{cls}">{allocated} de {available}</span> asignados',
    barTipOnDate: '<b>{resource}</b> on {startDate}<br><span class="{cls}">{allocated} de {available}</span> asignados',
    groupBarTipAssignment: '<b>{resource}</b> - <span class="{cls}">{allocated} de {available}</span>',
    groupBarTipInRange: '{startDate} - {endDate}<br><span class="{cls}">{allocated} de {available}</span> allocated:<br>{assignments}',
    groupBarTipOnDate: 'El {startDate}<br><span class="{cls}">{allocated} de {available}</span> asignado:<br>{assignments}',
    plusMore: "+{value} m\xE1s"
  },
  ResourceUtilization: {
    barTipInRange: '<b>{event}</b> {startDate} - {endDate}<br><span class="{cls}">{allocated}</span> asignado',
    barTipOnDate: '<b>{event}</b> el {startDate}<br><span class="{cls}">{allocated}</span> asignado',
    groupBarTipAssignment: '<b>{event}</b> - <span class="{cls}">{allocated}</span>',
    groupBarTipInRange: '{startDate} - {endDate}<br><span class="{cls}">{allocated} de {available}</span> asignado:<br>{assignments}',
    groupBarTipOnDate: 'El {startDate}<br><span class="{cls}">{allocated} de {available}</span> asignado:<br>{assignments}',
    plusMore: "+{value} m\xE1s",
    nameColumnText: "Recurso/Evento"
  },
  SchedulingIssueResolutionPopup: {
    "Cancel changes": "Cancelar el cambio y no hacer nada",
    schedulingConflict: "Conflicto de programaci\xF3n",
    emptyCalendar: "Error de configuraci\xF3n del calendario",
    cycle: "Programando ciclo",
    Apply: "Aplicar"
  },
  CycleResolutionPopup: {
    dependencyLabel: "Seleccione una dependencia:",
    invalidDependencyLabel: "Hay dependencias no v\xE1lidas implicadas que deben ser corregidas:"
  },
  DependencyEdit: {
    Active: "Activa"
  },
  SchedulerProBase: {
    propagating: "Calculando proyecto",
    storePopulation: "Cargando datos",
    finalizing: "Finalizando resultados"
  },
  EventSegments: {
    splitEvent: "Dividir evento",
    renameSegment: "Renombrar"
  },
  NestedEvents: {
    deNestingNotAllowed: "Desagrupado no permitido",
    nestingNotAllowed: "Agrupado permitido"
  },
  VersionGrid: {
    compare: "Comparar",
    description: "Descripci\xF3n",
    occurredAt: "Ocurri\xF3 en",
    rename: "Renombrar",
    restore: "Restaurar",
    stopComparing: "Detener comparaci\xF3n"
  },
  Versions: {
    entityNames: {
      TaskModel: "tarea",
      AssignmentModel: "asignaci\xF3n",
      DependencyModel: "enlace",
      ProjectModel: "proyecto",
      ResourceModel: "recurso",
      other: "objeto"
    },
    entityNamesPlural: {
      TaskModel: "tares",
      AssignmentModel: "asignaciones",
      DependencyModel: "enlaces",
      ProjectModel: "proyectos",
      ResourceModel: "recursos",
      other: "objetos"
    },
    transactionDescriptions: {
      update: "Se han cambiado {n} {entities}",
      add: "Se han a\xF1adido {n} {entities}",
      remove: "Se han quitado {n} {entities}",
      move: "Se han movido {n} {entities}",
      mixed: "Se han cambiado {n} {entities}"
    },
    addEntity: "Se ha a\xF1adido {type} **{name}**",
    removeEntity: "Se ha quitado {type} **{name}**",
    updateEntity: "Se ha cambiado {type} **{name}**",
    moveEntity: "Se ha movido {type} **{name}** de {from} a {to}",
    addDependency: "Se ha a\xF1adido el enlace de **{from}** a **{to}**",
    removeDependency: "Se ha quitado el enlace de **{from}** a **{to}**",
    updateDependency: "Se ha editado el enlace de **{from}** a **{to}**",
    addAssignment: "Se ha asignado **{resource}** a **{event}**",
    removeAssignment: "Se ha quitado la asignaci\xF3n de **{resource}** a **{event}**",
    updateAssignment: "Se ha editado la asignaci\xF3n de **{resource}** a **{event}**",
    noChanges: "Sin cambios",
    nullValue: "ninguno",
    versionDateFormat: "M/D/YYYY h:mm a",
    undid: "Se han deshecho los cambios",
    redid: "Se han rehecho los cambios",
    editedTask: "Se han editado las propiedades de tarea",
    deletedTask: "Se ha eliminado una tarea",
    movedTask: "Se ha movido una tarea",
    movedTasks: "Se han movido tareas"
  }
};
var Es_default = LocaleHelper.publishLocale(locale);
export { Es_default as default };