import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
import "@bryntum/scheduler-thin/lib/localization/It.js";
const locale = {
  localeName: "It",
  localeDesc: "Italiano",
  localeCode: "it",
  EventEdit: {
    Calendar: "Calendario",
    "All day": "Tutto il giorno",
    day: "Giorno",
    week: "Settimana",
    month: "Mese",
    year: "Anno",
    decade: "Decennio"
  },
  EventMenu: {
    duplicateEvent: "Duplica evento",
    copy: "copia"
  },
  Calendar: {
    toggleSidebar: "Attiva/disattiva la visibilit\xE0 della barra laterale",
    Today: "Oggi",
    Tomorrow: "Domani",
    next: range => `Prossimo ${range}`,
    previous: range => `Scorso ${range}`,
    plusMore: value => `altri +${value}`,
    allDay: "Tutto il giorno",
    endsOn: d => `Termina ${d}`,
    weekOfYear: ([y, w]) => `Settimana ${w}, ${y}`,
    loadFail: "Caricamento dei dati del calendario non riuscito. Contatta il tuo amministratore di sistema"
  },
  CalendarDrag: {
    holdCtrlForRecurrence: "Tieni premuto CTRL per un evento ricorrente"
  },
  CalendarMixin: {
    eventCount: count => `${count || "Nessun"} event${count && count > 1 ? "i" : "o"}`
  },
  EventTip: {
    "Edit event": "Modifica evento",
    timeFormat: "LST"
  },
  ModeSelector: {
    includeWeekends: "Includi fine settimana",
    weekends: "Fine settimana"
  },
  AgendaView: {
    Agenda: "Agenda"
  },
  MonthView: {
    Month: "Mese",
    monthUnit: "mese"
  },
  WeekView: {
    weekUnit: "settimana"
  },
  YearView: {
    Year: "Anno",
    yearUnit: "anno",
    noEvents: "Nessun evento"
  },
  EventList: {
    List: "Elenco",
    Start: "Inizio",
    Finish: "Fine",
    days: count => `${count > 1 ? `${count} ` : ""}giorn${count === 1 ? "o" : "i"}`
  },
  DayView: {
    Day: "Giorno",
    dayUnit: "giorno",
    daysUnit: "giorni",
    expandAllDayRow: "Espandi la sezione di tutti i giorni",
    collapseAllDayRow: "Comprimi la sezione di tutti i giorni",
    timeFormat: "LST",
    timeAxisTimeFormat: "LST",
    noMatchingDates: "Nessuna data corrispondente"
  },
  DayAgendaView: {
    dayAgenda: "Agenda del giorno"
  },
  DayResourceView: {
    dayResourceView: "Risorse giornaliere"
  },
  Sidebar: {
    "Filter events": "Filtra eventi"
  },
  WeekExpander: {
    expandTip: "Fai clic per espandere la riga",
    collapseTip: "Fai clic per comprimere la riga"
  }
};
var It_default = LocaleHelper.publishLocale(locale);
export { It_default as default };