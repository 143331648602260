import { createReducer, on } from '@ngrx/store';
import * as PriorityActions from './priority.actions';
import { PriorityDTO } from '../../ticket/ticket.model';

export interface PriorityState {
  priorities: PriorityDTO[]
}

export const priorityInitialState: PriorityState = {
  priorities: []
};

export const priorityReducer = createReducer(
  priorityInitialState,

  on(PriorityActions.getAllSuccess, (state, { priorities }) => ({ ...state, priorities: [...priorities] })),
  on(PriorityActions.getAllFailure, (state) => ({ ...state, priorities: [] })),

  on(PriorityActions.clearState, () => ({ ...priorityInitialState }))
);
