import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { RoleService } from '../services/role.service';
import * as RoleActions from './role.action';
import { map, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class RoleEffect extends NgRxEffectsBase {
  private readonly roleService = inject(RoleService);

  public getAllWebOperations$ = createEffect(() => this.actions$.pipe(
    ofType(RoleActions.getAllWebOperations),
    switchMap(() => this.roleService.getAllWebOperations().pipe(
      map(webOperations => RoleActions.getAllWebOperationsSuccess({ webOperations })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(RoleActions.getAllWebOperationsFailure({ httpStatus: null }))
    ))
  ));
}
