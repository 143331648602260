import { JobTypeDTO } from '../../job.model';
import { createReducer, on } from '@ngrx/store';
import * as JobTypesActions from './job-types.actions';
import { HttpStatus } from '../../../../shared/models/state-http-status';
import { Nullable } from '../../../../shared/models/types';
import { clone } from 'lodash-es';
import * as TdpJobTypesActions from '../../../../features/settings/store/actions/tdp-job-types.actions';
import { produce } from 'immer';

export interface JobTypesState {
  jobTypes: JobTypeDTO[]
  isLoading: boolean
  httpStatus: Nullable<HttpStatus>
}

const initialState: JobTypesState = {
  jobTypes: [],
  isLoading: false,
  httpStatus: null
};

export const jobTypesReducer = createReducer(
  initialState,

  on(JobTypesActions.allJobTypes, (state) => ({ ...state, isLoading: true, httpErrors: null })),
  on(JobTypesActions.allJobTypesSuccess, (state, { jobTypes }) => ({ ...state, isLoading: false, jobTypes: [...jobTypes] })),
  on(JobTypesActions.allJobTypesFailure, (state, { httpStatus }) => ({ ...state, isLoading: false, httpStatus: clone(httpStatus) })),

  on(TdpJobTypesActions.updateTemplateDatoPersSuccess, (state, { jobType }) => produce(state, draft => {
    if(jobType.deviceTypeId == null) {
      draft.jobTypes = draft.jobTypes.map(j => {
        if(j.id === jobType.id){
          j = jobType;
        }
        return j;
      });
    }
  })),

  on(JobTypesActions.clearState, () => ({ ...initialState }))
);
