import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take } from 'rxjs';
import * as OperatorSelectors from '../operators/store/operators.selector';
import { filterNotNull } from '../../shared/utility/rxjs-utils';
import { RoleService } from '../role/services/role.service';

export const loggedOperatorGuard: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);
  return store.select(OperatorSelectors.loggedOperator).pipe(
    filterNotNull,
    take(1),
    map(() => true)
  );
};

export const notLoggedOperatorGuard: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);
  return store.select(OperatorSelectors.loggedOperatorId).pipe(
    filter(value => value == null || value <= 0),
    take(1),
    map(() => true)
  );
};

export const redirectUserHomeGuard: CanMatchFn = (): Observable<UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);
  const roleService = inject(RoleService);
  return store.select(OperatorSelectors.loggedOperator).pipe(
    filterNotNull,
    take(1),
    map(operator => {
      const params = router.getCurrentNavigation()?.initialUrl.queryParams;
      let result: string[];
      const homePage = operator.operatorSettings?.otherSettings?.HOME_PAGE_FWO_FUNCTIONALITY;
      if(homePage){
        result = [roleService.getWebOperationPathById(homePage, operator)];
      }else{
        result = ['my-profile'];
      }
      return router.createUrlTree(result, { queryParams: params });
    })
  );
};
