import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { DeviceTypeService } from '../services/device-type.service';
import * as DeviceTypeActions from './device-types.actions';
import { map, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class DeviceTypesEffect extends NgRxEffectsBase {
  private readonly deviceTypesService = inject(DeviceTypeService);

  public getAllDeviceTypes$ = createEffect(() => this.actions$.pipe(
    ofType(DeviceTypeActions.retrieveAll),
    switchMap(() => this.deviceTypesService.getAllDeviceTypes()
      .pipe(
        map(deviceTypesList => DeviceTypeActions.retrieveAllSuccess({ deviceTypesList })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(DeviceTypeActions.retrieveAllFailure({ httpStatus: null }))
      )
    )
  ));

  public findAllByOpeId$ = createEffect(() => this.actions$.pipe(
    ofType(DeviceTypeActions.findAllByOpeRole),
    switchMap(() => this.deviceTypesService.getAllDeviceTypesByOpeRole(null).pipe(
      map(deviceTypes => DeviceTypeActions.findAllByOpeRoleSuccess({ deviceTypes })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(DeviceTypeActions.findAllByOpeRoleFailure({ httpStatus: null }))
    ))
  ));
}
