import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideExperimentalZonelessChangeDetection
} from '@angular/core';
import { provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';
import { AppRoutes } from '../app-routing';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import TranslocoConfig from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';
import { provideEffects } from '@ngrx/effects';
import { environment as Config } from '../../environments/environment';
import 'hammerjs';
import { DatePipe } from '@angular/common';
import { HammerModule, Title } from '@angular/platform-browser';
import { RxjsUtils } from '../shared/utility/rxjs-utils';
import { LoggerModule } from 'ngx-logger';
import { DisableBackButtonModule } from './disable-back-button.module';
import { KeycloakAngularModule } from 'keycloak-angular';
import { CustomPreloadingStrategy } from './router/custom-preloading-strategy';
import { provideCustomReuseStrategy, provideHammer, provideKeycloak } from './core.providers';
import { StoreEffects, StoreReducers } from "./store/store-config";
import { BaseHttpInterceptor } from './interceptors/inteceptors';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from "ngx-webstorage";
import { provideServiceWorker } from '@angular/service-worker';
import { provideSyncStore } from "@ngrx-addons/sync-state";
import { includeKeys } from "@ngrx-addons/common";

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([
        BaseHttpInterceptor
      ])
    ),
    provideTransloco(TranslocoConfig),
    provideStore(StoreReducers),
    provideSyncStore<typeof StoreReducers>({
      states: [
        { key: 'core', source: state => state.pipe(includeKeys(['sessionExpired'])) },
        { key: 'role' },
        { key: 'operators' },
        { key: 'customers' },
        { key: 'costAllocationTypes' },
        { key: 'costCenter' },
        { key: 'um' },
        { key: 'dashboard' },
        { key: 'licenses' },
        { key: 'datiPersonalizzati' },
        { key: 'deviceTypes' },
        { key: 'documentType' },
        { key: 'functions' },
        { key: 'item-types' },
        { key: 'jobTypes' },
        { key: 'priorities' },
        { key: 'settings-core' },
        { key: 'ticketCategories' },
        { key: 'ticketStates' },
        { key: 'widgets' },
      ]
    }),
    provideEffects(StoreEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: isDevMode(),
      traceLimit: 25,
      serialize: {
        options: {
          map: true
        }
      }
    }),
    provideRouterStore(),
    DatePipe,
    importProvidersFrom(
      Title,
      RxjsUtils,
      LoggerModule.forRoot({
        timestampFormat: 'dd/MM/YYYY HH:mm:ss',
        disableFileDetails: !isDevMode(),
        enableSourceMaps: isDevMode(),
        level: Config.loggerLevel
      }),
      DisableBackButtonModule,
      HammerModule,
      KeycloakAngularModule,
      RxReactiveFormsModule
    ),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: '', separator: '', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage()
    ),
    provideRouter(AppRoutes, withPreloading(CustomPreloadingStrategy), withComponentInputBinding()),
    provideCustomReuseStrategy(),
    provideKeycloak(),
    provideHammer(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
