import { inject, Injectable } from '@angular/core';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { DocumentTypeService } from '../services/document-type.service';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { Action } from '@ngrx/store';
import { DocumentTypeApiActions } from './document-type.actions';
import { translate } from '@jsverse/transloco';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { HttpStatus, HttpStatusTypes } from '../../../shared/models/state-http-status';
import { delayRequest } from '../../../shared/utility/rxjs-utils';
import * as RouterActions from '../../router/store/router.actions';
import { CoreDefaultActions } from '../../store/core.actions';

@Injectable({ providedIn: 'any' })
export class DocumentTypeEffects extends NgRxEffectsBase {

  private readonly documentTypeService = inject(DocumentTypeService);

  public getAll$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentTypeApiActions.getAll),
    switchMap(() => this.documentTypeService.getAll().pipe(
      map(documentTypes => DocumentTypeApiActions.getAllSuccess({ documentTypes })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(DocumentTypeApiActions.getAllFailure({ httpStatus: null }))
    ))
  ));

  public create$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentTypeApiActions.create),
    switchMap(({ documentType, redirectToDetail, actions }) => delayRequest(this.documentTypeService.createDocumentType(documentType)).pipe(
      mergeMap(documentType => {
        let result: Action[] = [DocumentTypeApiActions.createSuccess({ documentType })];
        if (redirectToDetail) {
          result.push(RouterActions.go({ path: ['settings/document-types/list/edit', `${documentType.id}`], extras: { queryParamsHandling: 'merge' } }));
        }
        if (actions) {
          result = [...result, ...actions];
        }
        return result;
      }),
      catchError((err: HttpErrorResponse) => of(DocumentTypeApiActions.createFailure({
        httpStatus: {
          type: 'error',
          text: this.translocoService.translate('errorCreateDocumentType')
        }
      })))
    ))
  ));

  public update$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentTypeApiActions.update),
    switchMap(({ documentType }) => this.documentTypeService.updateDocumentType(documentType).pipe(
      map(documentType => DocumentTypeApiActions.updateSuccess({ documentType })),
      catchError((e: HttpErrorResponse) => {
        const httpStatus: HttpStatus = {
          type: 'error',
          text: translate('errorUpdateDocumentType')
        };
        const actions: Action[] = [];
        if(e.status === HttpStatusCode.Conflict) {
          httpStatus.text = '';
          const [title, msg1, msg2, msg3] = this.translocoService.translate(['warning', 'dataIsNotUpToDate', 'askLoadUpdatedData', 'allChangesWillBeLost']);
          actions.push(
            CoreDefaultActions.confirmThenDoAction({
              title: `${title}!`,
              msg: `<p class="tw-pb-3">${msg1}. ${msg2}</p><p>${msg3}</p>`,
              actions: [DocumentTypeApiActions.getDocumentTypeById( { id: documentType.id })]
            })
          );
        }
        return [DocumentTypeApiActions.updateFailure({ httpStatus }), ...actions ];
      })
    ))
  ));

  public delete$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentTypeApiActions.delete),
    switchMap(({ id }) => this.documentTypeService.deleteDocumentType(id).pipe(
      map(() => DocumentTypeApiActions.deleteSuccess({ id })),
      this.rxjsUtils.advancedCatchErrorWithLog<Action>((err: HttpErrorResponse) => {
        let type: HttpStatusTypes = 'error';
        let errText = 'errorDeleteDocumentType';
        if(err.status === HttpStatusCode.Forbidden){
          type = 'warning';
          errText = 'errorDeleteDocumentTypeLinks';
        }
        return of(DocumentTypeApiActions.deleteFailure({ httpStatus: { type, text: translate(errText) } }));
      })
    ))
  ));

}
