import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
const locale = {
  localeName: "Es",
  localeDesc: "Espa\xF1ol",
  localeCode: "es",
  Object: {
    Yes: "S\xED",
    No: "No",
    Cancel: "Cancelar",
    Ok: "Correcto",
    Week: "Semana",
    None: "Ninguno"
  },
  CodeEditor: {
    apply: "Aplicar",
    autoApply: "Aplicaci\xF3n autom\xE1tica",
    downloadCode: "Descargar c\xF3digo",
    editor: "Editor de c\xF3digo",
    viewer: "Visor de c\xF3digo"
  },
  ColorPicker: {
    noColor: "Sin color"
  },
  Combo: {
    noResults: "Sin resultados",
    recordNotCommitted: "No se ha podido a\xF1adir un registro",
    addNewValue: value => `Agregar ${value}`
  },
  FilePicker: {
    file: "Archivo"
  },
  Field: {
    badInput: "Valor de campo no v\xE1lido",
    patternMismatch: "El valor debe coincidir con un patr\xF3n espec\xEDfico",
    rangeOverflow: value => `El valor debe ser inferior o igual a ${value.max}`,
    rangeUnderflow: value => `El valor debe ser superior o igual a ${value.min}`,
    stepMismatch: "El valor debe adaptarse al paso",
    tooLong: "El valor debe ser m\xE1s corto",
    tooShort: "El valor debe ser m\xE1s largo",
    typeMismatch: "El valor debe estar en un formato especial",
    valueMissing: "Este campo es obligatorio",
    invalidValue: "Valor de campo no v\xE1lido",
    minimumValueViolation: "Infracci\xF3n de valor m\xEDnimo",
    maximumValueViolation: "Infracci\xF3n de valor m\xE1ximo",
    fieldRequired: "Este campo es obligatorio",
    validateFilter: "El valor debe seleccionarse de la lista"
  },
  DateField: {
    invalidDate: "Entrada de fecha no v\xE1lida"
  },
  DatePicker: {
    gotoPrevYear: "Ir al a\xF1o anterior",
    gotoPrevMonth: "Ir al mes anterior",
    gotoNextMonth: "Ir al mes siguiente",
    gotoNextYear: "Ir al a\xF1o siguiente"
  },
  NumberFormat: {
    locale: "es",
    currency: "EUR"
  },
  DurationField: {
    invalidUnit: "Unidad no v\xE1lida"
  },
  TimeField: {
    invalidTime: "Entrada de hora no v\xE1lida"
  },
  TimePicker: {
    hour: "Hora",
    minute: "Minuto",
    second: "Segundo"
  },
  List: {
    loading: "Cargando...",
    selectAll: "Seleccionar todo"
  },
  GridBase: {
    loadMask: "Cargando...",
    syncMask: "Guardando cambios, espere..."
  },
  PagingToolbar: {
    firstPage: "Ir a la primera p\xE1gina",
    prevPage: "Ir a la p\xE1gina anterior",
    page: "P\xE1gina",
    nextPage: "Ir a la p\xE1gina siguiente",
    lastPage: "Ir a la \xFAltima p\xE1gina",
    reload: "Refrescar la p\xE1gina actual",
    noRecords: "Sin registros que mostrar",
    pageCountTemplate: data => `de ${data.lastPage}`,
    summaryTemplate: data => `Mostrando registros ${data.start} - ${data.end} de ${data.allCount}`
  },
  PanelCollapser: {
    Collapse: "Contrar",
    Expand: "Expandir"
  },
  Popup: {
    close: "Cerrar desplegable"
  },
  UndoRedo: {
    Undo: "Deshacer",
    Redo: "Rehacer",
    UndoLastAction: "Deshacer la \xFAltima acci\xF3n",
    RedoLastAction: "Rehacer la \xFAltima acci\xF3n deshecha",
    NoActions: "Sin elementos en la cola de deshacer"
  },
  FieldFilterPicker: {
    equals: "equivale a",
    doesNotEqual: "no equivale a",
    isEmpty: "est\xE1 vac\xEDo",
    isNotEmpty: "no est\xE1 vac\xEDo",
    contains: "contiene",
    doesNotContain: "no contiene",
    startsWith: "empieza por",
    endsWith: "termina por",
    isOneOf: "es uno de",
    isNotOneOf: "no es uno de",
    isGreaterThan: "es mayor que",
    isLessThan: "es menor que",
    isGreaterThanOrEqualTo: "es mayor que o igual a",
    isLessThanOrEqualTo: "es menor que o igual a",
    isBetween: "est\xE1 entre",
    isNotBetween: "no est\xE1 entre",
    isBefore: "es anterior",
    isAfter: "es posterior",
    isToday: "es hoy",
    isTomorrow: "es ma\xF1ana",
    isYesterday: "fue ayer",
    isThisWeek: "es esta semana",
    isNextWeek: "es la semana que viene",
    isLastWeek: "fue la semana pasada",
    isThisMonth: "es este mes",
    isNextMonth: "es el mes que viene",
    isLastMonth: "fue el mes pasado",
    isThisYear: "es este a\xF1o",
    isNextYear: "es el a\xF1o que viene",
    isLastYear: "fue el a\xF1o pasado",
    isYearToDate: "es el a\xF1o hasta la fecha",
    isTrue: "es cierto",
    isFalse: "es falso",
    selectAProperty: "Seleccionar una propiedad",
    selectAnOperator: "Seleccionar un operador",
    caseSensitive: "Diferencia entre may\xFAsculas y min\xFAsculas",
    and: "y",
    dateFormat: "D/M/YY",
    selectValue: "Seleccione valor",
    selectOneOrMoreValues: "Seleccionar uno o m\xE1s valores",
    enterAValue: "Introducir un valor",
    enterANumber: "Introducir un n\xFAmero",
    selectADate: "Seleccionar fecha",
    selectATime: "Seleccionar hora"
  },
  FieldFilterPickerGroup: {
    addFilter: "A\xF1adir filtro"
  },
  DateHelper: {
    locale: "es",
    weekStartDay: 1,
    nonWorkingDays: {
      0: true,
      6: true
    },
    weekends: {
      0: true,
      6: true
    },
    unitNames: [{
      single: "milisegundo",
      plural: "ms",
      abbrev: "ms"
    }, {
      single: "emillisecond",
      plural: "ems",
      abbrev: "ems"
    }, {
      single: "segundo",
      plural: "segundos",
      abbrev: "s"
    }, {
      single: "esecond",
      plural: "eseconds",
      abbrev: "es"
    }, {
      single: "minuto",
      plural: "minutos",
      abbrev: "min"
    }, {
      single: "eminute",
      plural: "eminutes",
      abbrev: "emin"
    }, {
      single: "hora",
      plural: "horas",
      abbrev: "h"
    }, {
      single: "ehour",
      plural: "ehours",
      abbrev: "eh"
    }, {
      single: "d\xEDa",
      plural: "d\xEDas",
      abbrev: "d"
    }, {
      single: "eday",
      plural: "edays",
      abbrev: "ed"
    }, {
      single: "semana",
      plural: "semanas",
      abbrev: "sem."
    }, {
      single: "eweek",
      plural: "eweeks",
      abbrev: "ew"
    }, {
      single: "mes",
      plural: "meses",
      abbrev: "mes"
    }, {
      single: "emonth",
      plural: "emonths",
      abbrev: "emon"
    }, {
      single: "trimestre",
      plural: "trimestres",
      abbrev: "trim."
    }, {
      single: "equarter",
      plural: "equarters",
      abbrev: "eq"
    }, {
      single: "a\xF1o",
      plural: "a\xF1os",
      abbrev: "a."
    }, {
      single: "eyear",
      plural: "eyears",
      abbrev: "eyr"
    }, {
      single: "d\xE9cada",
      plural: "d\xE9cadas",
      abbrev: "d\xE9c."
    }, {
      single: "edecade",
      plural: "edecades",
      abbrev: "edec"
    }],
    unitAbbreviations: [["mil"], [], ["s", "seg"], [], ["m", "min"], [], ["h", "hr"], [], ["d"], [], ["sem.", "sem"], [], ["m", "mes"], [], ["T", "trim"], [], ["a", "a\xF1"], [], ["d\xE9c"], []],
    parsers: {
      L: "DD/MM/YYYY",
      LT: "HH:mm",
      LTS: "HH:mm:ss A"
    },
    ordinalSuffix: number => number + "\xB0"
  }
};
var Es_default = LocaleHelper.publishLocale(locale);
export { Es_default as default };