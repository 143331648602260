import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HammerConfig } from './hammerJs/hammer-config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakBearerInterceptor, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { asapScheduler, from } from 'rxjs';
import * as AuthActions from './auth/store/auth.actions';
import { LocalStorageKeysEnum } from '../shared/models/enums';
import * as GlobalLanguage from './language/language-global';
import * as KeycloakSettings from './sso/keycloak-settings';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './router/custom-reuse-strategy';
import { environment as Config } from "../../environments/environment";
import { BroadcastService, TOKEN_BROADCAST_DI_TOKEN } from "./broadcast/broadcast.service";
import { BroadcastMessageType } from "./broadcast/broadcast.model";
import { CoreDefaultActions } from "./store/core.actions";

// Configurazione keycloak
export const provideKeycloak = (): EnvironmentProviders => makeEnvironmentProviders([
  { provide: HTTP_INTERCEPTORS, useClass: KeycloakBearerInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: (keycloak: KeycloakService, store: Store, ls: LocalStorageService, tokenCh: BroadcastService<number>) => async () => {
      let tokenTime = 0;
      from(keycloak.keycloakEvents$).subscribe(e => {
        switch (e.type) {
          case KeycloakEventType.OnAuthSuccess: {
            store.dispatch(AuthActions.login());
            break;
          }
          case KeycloakEventType.OnAuthRefreshError:
            if(keycloak.isLoggedIn()) {
              // In caso di errore dovuto ad assenza di rete retry del refresh token
              asapScheduler.schedule(() => {
                keycloak.updateToken(20);
              }, 5000);
            } else {
              store.dispatch(CoreDefaultActions.setSessionExpired({ sessionExpired: true }));
            }
            break;
          case KeycloakEventType.OnAuthRefreshSuccess:
            /*
              Utilizzato per indicare alle altre tab di effettuare un controllo sul token.
              Utilizzo il metodo getTime() per avere un valore sempre diverso nella localStorage,
              in questo modo viene triggerato l'evento 'storage' nelle altre tab e posso invocare il metoto updateToken
              della libreria di keycloak.
            */
            tokenTime = new Date().getTime();
            if('BroadcastChannel' in window) {
              tokenCh.publish({ type: BroadcastMessageType.UPDATE_TOKEN, payload: tokenTime });
            } else {
              ls.store(LocalStorageKeysEnum.updateToken, tokenTime);
            }
            break;
        }
      });

      const updateToken = (v: number) => {
        if(tokenTime !== v) {
          tokenTime = v;
          /*
            Il valore deve essere maggiore della durata di sessione in quanto non sappiamo quando l'utente
            potrebbe aprire una seconda tab, di conseguenza forziamo sempre il refresh del token.
          */
          keycloak.updateToken(20);
        }
      };
      /* Resto in ascolto delle modifiche al valore updateToken */
      if('BroadcastChannel' in window) {
        tokenCh.messagesOfType(BroadcastMessageType.UPDATE_TOKEN).subscribe(({ payload }) => {
          updateToken(payload);
        });
      } else {
        ls.observe(LocalStorageKeysEnum.updateToken).subscribe(v => {
          updateToken(v as number);
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression,@typescript-eslint/await-thenable
      await GlobalLanguage.setLanguage('it');
      const config = await KeycloakSettings.getSettingsAsync();
      return await keycloak.init({
        config,
        initOptions: {
          pkceMethod: 'S256',
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: `${window.location.origin}${Config.baseHref}/assets/silent-check-sso.html`,
          silentCheckSsoFallback: false
        },
        enableBearerInterceptor: true,
        shouldAddToken: () => true,
        updateMinValidity: 10
      });
    },
    multi: true,
    deps: [KeycloakService, Store, LocalStorageService, TOKEN_BROADCAST_DI_TOKEN]
  }
]);

export const provideHammer = (): EnvironmentProviders => makeEnvironmentProviders([
  { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig }
]);

export const provideCustomReuseStrategy = (): EnvironmentProviders => makeEnvironmentProviders([
  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
]);
