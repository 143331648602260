import { createReducer, on } from '@ngrx/store';
import { FunctionDTO } from '../functions.model';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { Nullable } from '../../../shared/models/types';
import { FunctionsApiActions, FunctionsDefaultActions } from './functions.action';
import { produce } from 'immer';
import { ArrayUtils } from '../../../shared/utility/ArrayUtils';

export interface FunctionsState {
  functionList: FunctionDTO[]
  functionsForOperator: FunctionDTO[]
  isLoading: boolean
  httpStatus: Nullable<HttpStatus>
}

export const initialState: FunctionsState = {
  functionList: [],
  functionsForOperator: [],
  isLoading: false,
  httpStatus: null
};

const isLoadingTrueActions = [
  FunctionsApiActions.getAll,  FunctionsApiActions.getAllForOperator, FunctionsApiActions.getById, FunctionsApiActions.create,
  FunctionsApiActions.update, FunctionsApiActions.delete
];

const failureActions = [
  FunctionsApiActions.getAllFailure, FunctionsApiActions.getAllForOperatorFailure, FunctionsApiActions.getByIdFailure,
  FunctionsApiActions.createFailure, FunctionsApiActions.updateFailure, FunctionsApiActions.deleteFailure
];

export const functionsReducer = createReducer(
  initialState,

  on(...isLoadingTrueActions, (state) => ({ ...state, isLoading: true })),

  on(FunctionsApiActions.getAllSuccess, (state, { functionList }) => produce(state, draft => {
    draft.functionList = functionList;
    draft.isLoading = false;
  })),

  on(FunctionsApiActions.getAllForOperatorSuccess, (state, { functionsForOperator }) => produce(state, draft => {
    draft.functionsForOperator = functionsForOperator;
    draft.isLoading = false;
  })),

  on(FunctionsApiActions.getAllSuccess, (state, { functionList }) => produce(state, draft => {
    draft.functionList = functionList;
    draft.isLoading = false;
  })),

  on(FunctionsApiActions.createSuccess, (state, payload) => produce(state, draft => {
    draft.functionList.push(payload.func);
    draft.isLoading = false;
  })),

  on(
    FunctionsApiActions.updateSuccess, FunctionsApiActions.getByIdSuccess,
    (state, payload) => produce(state, draft => {
      draft.functionList = ArrayUtils.updateArrayByObjProp(draft.functionList, 'id', payload.func);
      draft.isLoading = false;
    })
  ),

  on(FunctionsApiActions.refreshExecutors, (state) => ({ ...state, isLoading: true })),
  on(FunctionsApiActions.refreshExecutorsSuccess, (state) => produce(state, draft => {
    draft.isLoading = false;
  })),
  on(FunctionsApiActions.refreshExecutorsFailure, (state, { httpStatus }) => produce(state, draft => {
    draft.httpStatus = httpStatus;
    draft.isLoading = false;
  })),

  on(FunctionsApiActions.deleteSuccess, (state, { id }) => produce(state, draft => {
    draft.functionList = draft.functionList.filter(f => f.id !== id);
    draft.isLoading = false;
  })),

  on(...failureActions, (state, { httpStatus }) => produce(state, draft => {
    draft.httpStatus = httpStatus;
    draft.isLoading = false;
  })),

  on(FunctionsDefaultActions.clearErrors, (state) => ({ ...state, httpStatus: null })),
  on(FunctionsDefaultActions.clearState, () => ({ ...initialState }))
);
