export default {
  time: {
    month: [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ],
    monthAbbr: [
      'ene', 'feb', 'mar', 'abr', 'may', 'jun',
      'jul', 'ago', 'sep', 'oct', 'nov', 'dic'
    ],
    dayOfWeek: [
      'domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'
    ],
    dayOfWeekAbbr: [
      'dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sáb'
    ]
  },
  legend: {
    selector: {
      all: 'Todas',
      inverse: 'Inversa'
    }
  },
  toolbox: {
    brush: {
      title: {
        rect: 'Selección de cuadro',
        polygon: 'Selección de lazo',
        lineX: 'Seleccionar horizontalmente',
        lineY: 'Seleccionar verticalmente',
        keep: 'Mantener selección',
        clear: 'Despejar selecciones'
      }
    },
    dataView: {
      title: 'Ver datos',
      lang: ['Ver datos', 'Cerrar', 'Actualizar']
    },
    dataZoom: {
      title: {
        zoom: 'Zoom',
        back: 'Restablecer zoom'
      }
    },
    magicType: {
      title: {
        line: 'Cambiar a gráfico de líneas',
        bar: 'Cambiar a gráfico de barras',
        stack: 'Pila',
        tiled: 'Teja'
      }
    },
    restore: {
      title: 'Restaurar'
    },
    saveAsImage: {
      title: 'Guardar como imagen',
      lang: ['Clic derecho para guardar imagen']
    }
  },
  series: {
    typeNames: {
      pie: 'Gráfico circular',
      bar: 'Gráfico de barras',
      line: 'Gráfico de líneas',
      scatter: 'Diagrama de dispersión',
      effectScatter: 'Diagrama de dispersión de ondas',
      radar: 'Gráfico de radar',
      tree: 'Árbol',
      treemap: 'Mapa de árbol',
      boxplot: 'Diagrama de caja',
      candlestick: 'Gráfico de velas',
      k: 'Gráfico de líneas K',
      heatmap: 'Mapa de calor',
      map: 'Mapa',
      parallel: 'Mapa de coordenadas paralelas',
      lines: 'Gráfico de líneas',
      graph: 'Gráfico de relaciones',
      sankey: 'Diagrama de Sankey',
      funnel: 'Gráfico de embudo',
      gauge: 'Medidor',
      pictorialBar: 'Gráfico de barras pictóricas',
      themeRiver: 'Mapa de río temático',
      sunburst: 'Sunburst',
      custom: 'Gráfico personalizado',
      chart: 'Gráfico'
    }
  },
  aria: {
    general: {
      withTitle: 'Este es un gráfico sobre “{title}”',
      withoutTitle: 'Este es un gráfico'
    },
    series: {
      single: {
        prefix: '',
        withName: ' con tipo {seriesType} llamado {seriesName}.',
        withoutName: ' con tipo {seriesType}.'
      },
      multiple: {
        prefix: '. Consta de {seriesCount} series.',
        withName: ' La serie {seriesId} es un {seriesType} que representa {seriesName}.',
        withoutName: ' La serie {seriesId} es un {seriesType}.',
        separator: {
          middle: '',
          end: ''
        }
      }
    },
    data: {
      allData: 'Los datos son los siguientes: ',
      partialData: 'Los primeros {displayCnt} elementos son: ',
      withName: 'los datos para {name} son {value}',
      withoutName: '{value}',
      separator: {
        middle: ', ',
        end: '. '
      }
    }
  }
};