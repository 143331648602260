import { createReducer, on } from '@ngrx/store';
import * as TicketCategoriesAction from './ticket-categories.action';
import { TicketCategoriesDTO } from '../../ticket/ticket.model';

export interface TicketCategoriesState {
  allCategories: TicketCategoriesDTO[]
  isLoading: boolean
}

export const ticketCategoriesInitialState: TicketCategoriesState = {
  allCategories: [],
  isLoading: false
};

export const ticketCategoriesReducer = createReducer(
  ticketCategoriesInitialState,

  on(TicketCategoriesAction.retrieveAll, (state) => ({ ...state, isLoading: true })),
  on(TicketCategoriesAction.retrieveAllSucces, (state, { categories }) => ({ ...state, allCategories: [...categories], isLoading: false })),
  on(TicketCategoriesAction.retrieveAllFailure, (state) => ({ ...state, isLoading: false })),

  on(TicketCategoriesAction.clearState, () => ({ ...ticketCategoriesInitialState }))
);
