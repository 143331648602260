import { createAction, props } from '@ngrx/store';
import { WebOperationDTO } from '../role.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';

/* DEFAULT ACTIONS */
export const clearState = createAction('[Roles] clear state');

/* API ACTIONS */
export const getAllWebOperations = createAction('[Roles/API] get all web operations');
export const getAllWebOperationsSuccess = createAction('[Roles/API] get all web operations success', props<{ webOperations: WebOperationDTO[] }>());
export const getAllWebOperationsFailure = NgRxUtils.createHttpStatusAction('[Roles/API] get all web operations failure');
