import { DashboardDTO } from '../dashboard.model';
import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.action';

export interface DashboardState {
  allOperatorDashboards: DashboardDTO[]
  isLoading: boolean
}

export const dashboardInitialState: DashboardState = {
  allOperatorDashboards: [],
  isLoading: false
};

export const dashboardReducer = createReducer(
  dashboardInitialState,

  on(DashboardActions.getAllByOpeId, (state) => ({ ...state, isLoading: true })),
  on(DashboardActions.getAllByOpeIdSuccess, (state, { dashboardList }) => ({ ...state, allOperatorDashboards: [...dashboardList], isLoading: false })),
  on(DashboardActions.getAllByOpeIdFailure, (state) => ({ ...state, isLoading: false })),

  on(DashboardActions.clearState, () => ({ ...dashboardInitialState }))
);
