import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { DashboardService } from '../services/dashboard.service';
import * as DashboardActions from './dashboard.action';
import { map, switchMap, tap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class DashboardEffect extends NgRxEffectsBase {
  private readonly dashboardService = inject(DashboardService);

  public getAllByOpeId$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.getAllByOpeId),
    tap(() => {
      this.logger.log('DashboardEffect - getAllByOpeId', new Date());
    }),
    switchMap(() => this.dashboardService.getDashBoardListByOpeId().pipe(
      map(dashboardList => DashboardActions.getAllByOpeIdSuccess({ dashboardList })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(DashboardActions.getAllByOpeIdFailure({ httpStatus: null }))
    ))
  ));
}
