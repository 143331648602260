import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
const locale = {
  localeName: "It",
  localeDesc: "Italiano",
  localeCode: "it",
  RemoveDependencyCycleEffectResolution: {
    descriptionTpl: "Rimuovi dipendenza"
  },
  DeactivateDependencyCycleEffectResolution: {
    descriptionTpl: "Disattiva dipendenza"
  },
  CycleEffectDescription: {
    descriptionTpl: "\xC8 stato trovato un ciclo, formato da: {0}"
  },
  EmptyCalendarEffectDescription: {
    descriptionTpl: `L'attivit\xE0 "{2}" non pu\xF2 essere eseguita. Il calendario "{0}" non fornisce intervalli di tempo di lavoro.`,
    forwardDescriptionTpl: `L'attivit\xE0 "{3}" non pu\xF2 essere eseguita. Il calendario "{0}" non ha tempo di lavoro dopo {1}.`,
    backwardDescriptionTpl: `L'attivit\xE0 "{3}" non pu\xF2 essere eseguita. Il calendario "{0}" non ha tempo di lavoro prima di {1}.`,
    noIntersectionDescriptionTpl: `L'attivit\xE0 "{3}" non pu\xF2 essere eseguita. Il suo calendario "{0}" e i suoi calendari delle risorse non hanno tempo di lavoro comune.`
  },
  Use24hrsEmptyCalendarEffectResolution: {
    descriptionTpl: "Usa il calendario di 24 ore con sabati e domeniche non lavorativi."
  },
  Use8hrsEmptyCalendarEffectResolution: {
    descriptionTpl: "Usa il calendario di 8 ore (08:00-12:00, 13:00-17:00) con sabati e domeniche non lavorativi."
  },
  IgnoreProjectConstraintResolution: {
    descriptionTpl: "Ignora il bordo del progetto e procedi con il cambiamento."
  },
  ProjectConstraintConflictEffectDescription: {
    startDescriptionTpl: `Hai spostato l'attivit\xE0 "{0}" per farla iniziare in data {1}, ma \xE8 antecedente alla data di inizio progetto: {2}.`,
    endDescriptionTpl: `Hai spostato l'attivit\xE0 "{0}" per finire {1}. Questo \xE8 dopo la data di fine del progetto {2}.`
  },
  HonorProjectConstraintResolution: {
    descriptionTpl: "Regola il compito per onorare il bordo del progetto."
  },
  IgnoreResourceCalendarEmptyCalendarEffectResolution: {
    descriptionTpl: "Ignora i calendari delle risorse e pianifica l'attivit\xE0 solo con il proprio calendario."
  },
  ConflictEffectDescription: {
    descriptionTpl: "\xC8 stato trovato un conflitto di programmazione: {0} \xE8 in conflitto con {1}"
  },
  ConstraintIntervalDescription: {
    dateFormat: "LLL"
  },
  ProjectConstraintIntervalDescription: {
    startDateDescriptionTpl: "Data d\u2019inizio del progetto {0}",
    endDateDescriptionTpl: "Data di fine del progetto {0}"
  },
  DependencyType: {
    long: ["Inizio-Inizio", "Inizio-Fine", "Fine-Inizio", "Fine-Fine"]
  },
  ManuallyScheduledParentConstraintIntervalDescription: {
    startDescriptionTpl: '"{2}" programmato manualmente forza l\u2019avvio degli elementi secondari a non prima di {0}',
    endDescriptionTpl: '"{2}" programmato manualmente forza la fine degli elementi secondari entro e non oltre {1}'
  },
  DisableManuallyScheduledConflictResolution: {
    descriptionTpl: 'Disabilita programmazione manuale per "{0}"'
  },
  DependencyConstraintIntervalDescription: {
    descriptionTpl: 'Dipendenza ({2}) da "{3}" a "{4}"'
  },
  RemoveDependencyResolution: {
    descriptionTpl: 'Rimuovi dipendenza da "{1}" a "{2}"'
  },
  DeactivateDependencyResolution: {
    descriptionTpl: 'Disattiva dipendenza da "{1}" a "{2}"'
  },
  DateConstraintIntervalDescription: {
    startDateDescriptionTpl: 'Vincolo compito "{2}" {3} {0}',
    endDateDescriptionTpl: 'Vincolo compito "{2}" {3} {1}',
    constraintTypeTpl: {
      startnoearlierthan: "Inizio non prima di",
      finishnoearlierthan: "Fine non prima di",
      muststarton: "Deve iniziare il",
      mustfinishon: "Deve finire il",
      startnolaterthan: "Inizio non oltre",
      finishnolaterthan: "Fine non oltre"
    }
  },
  RemoveDateConstraintConflictResolution: {
    descriptionTpl: 'Rimuovi vincolo "{1}" del compito "{0}"'
  },
  PostponeDateConstraintConflictResolution: {
    descriptionTpl: `Rimandare la risoluzione e contrassegnare l'attivit\xE0 "{0}" con un indicatore di conflitto`
  }
};
var It_default = LocaleHelper.publishLocale(locale);
export { It_default as default };