import { DeviceTypeDTO } from '../device-types.model';
import { createReducer, on } from '@ngrx/store';
import * as DeviceTypesActions from './device-types.actions';
import * as SettingsDeviceTypesActions from '../../../features/settings/store/actions/device-types.actions';
import { updateArrayObj } from '../../../shared/utility/utility';

export interface DeviceTypesState {
  deviceTypesList: DeviceTypeDTO[]
  isLoading: boolean
}

export const deviceTypeInitialState: DeviceTypesState = {
  deviceTypesList: [],
  isLoading: false
};

export const deviceTypesReducer = createReducer(
  deviceTypeInitialState,

  on(DeviceTypesActions.retrieveAll, (state) => ({ ...state, isLoading: true })),
  on(DeviceTypesActions.retrieveAllSuccess, (state, { deviceTypesList }) => ({ ...state, deviceTypesList: [...deviceTypesList], isLoading: false })),
  on(DeviceTypesActions.retrieveAllFailure, (state) => ({ ...state, isLoading: false })),

  on(SettingsDeviceTypesActions.createDeviceTypeSuccess, (state, { deviceType }) => ({ ...state, deviceTypesList: [...[deviceType], ...state.deviceTypesList] })),
  on(SettingsDeviceTypesActions.updateDeviceTypeSuccess, (state, { deviceType }) => ({ ...state, deviceTypesList: updateArrayObj(state.deviceTypesList, 'id', deviceType) })),
  on(SettingsDeviceTypesActions.deleteDeviceTypeSuccess, (state, { id }) => ({ ...state, deviceTypes: state.deviceTypesList.filter(d => d.id !== id) })),

  on(DeviceTypesActions.clearState, () => ({ ...deviceTypeInitialState }))
);
