import { inject, InjectionToken } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';
import { BroadcastChannelType, BroadcastMessage, BroadcastMessageType } from './broadcast.model';
import { AppGlobalState } from '../store/store-config';
import { Action } from '@ngrx/store';
import { Nullable } from "../../shared/models/types";
import { NGXLogger } from "ngx-logger";

/* Classe da utilizzare come broker di messaggistica per sincronizzare tab e iframe */
export class BroadcastService<T> {

  readonly #broadcastChannel: Nullable<BroadcastChannel>;
  readonly #onMessage = new Subject<BroadcastMessage<T>>();
  readonly #logger = inject(NGXLogger);
  // TODO Da rivedere in caso di migrazione a sistema multi tenant
  readonly #origin = location.pathname.split('/').filter(a => a !== '')[0];

  constructor (name: BroadcastChannelType) {
    if('BroadcastChannel' in window) {
      this.#broadcastChannel = new BroadcastChannel(`${name}-ch`);
      this.#broadcastChannel.onmessage = (message: MessageEvent<Omit<BroadcastMessage<T>, 'origin'>>): void => {
        this.#onMessage.next({ ...message.data, origin: this.#origin });
      };
    } else {
      this.#logger.warn('BroadcastChannel API not available');
    }
  }

  public publish (message: Omit<BroadcastMessage<T>, 'origin'>): void {
    this.#broadcastChannel?.postMessage(message);
  }

  public messagesOfType (type: BroadcastMessageType): Observable<BroadcastMessage<T>> {
    return this.#onMessage.pipe(
      filter(message => message.type === type && message.origin === this.#origin)
    );
  }

}

// Canale di broadcast per gestire lo stato applicativo tra le eventuali finestre/tab aperte
export const GLOBAL_STATE_BROADCAST_DI_TOKEN = new InjectionToken<BroadcastService<AppGlobalState | Action>>('global-state-ch', {
  providedIn: 'root',
  factory: (): BroadcastService<AppGlobalState | Action> => {
    return new BroadcastService('global-state');
  }
});

// Canale di broadcast per gestire il refresh del token tra le finestre/tab aperte
export const TOKEN_BROADCAST_DI_TOKEN = new InjectionToken<BroadcastService<number>>('token-ch', {
  providedIn: 'root',
  factory: (): BroadcastService<number> => {
    return new BroadcastService('token');
  }
});
