import { TemplateDatoPers } from '../dato-personalizzato.model';
import { createReducer, on } from '@ngrx/store';
import { DatoPersonalizzatoApiActions, DatoPersonalizzatoDefaultActions } from './dato-personalizzato.actions';
import { Nullable } from '../../../shared/models/types';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';

export interface DatiPersonalizzatiState {
  allTemplateDatoPers: TemplateDatoPers[]
  isLoading: boolean // Indica se è in corso un caricamento
  httpStatus: Nullable<HttpStatus>
}

export const datiPersonalizzatiInitialState: DatiPersonalizzatiState = {
  allTemplateDatoPers: [],
  isLoading: false,
  httpStatus: null
};

export const datiPersonalizzatiReducer = createReducer(
  datiPersonalizzatiInitialState,

  on(DatoPersonalizzatoApiActions.allTemplateDatoPersSuccess, (state, { datiPersonalizzati }) => ({ ...state, allTemplateDatoPers: [...datiPersonalizzati] })),
  on(DatoPersonalizzatoApiActions.allTemplateDatoPersFailure, (state) => ({ ...state })),

  on(DatoPersonalizzatoApiActions.createTemplateDatoPers, (state) => ({ ...state, isLoading: true})),
  on(DatoPersonalizzatoApiActions.createTemplateDatoPersSuccess, (state, { template}) => produce( state, draft => {
    draft.isLoading = false;
    draft.allTemplateDatoPers.push(template);
  })),
  on(DatoPersonalizzatoApiActions.createTemplateDatoPersFailure, (state, {httpStatus}) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false})),

  on(DatoPersonalizzatoApiActions.getTemplateDatoPersById, DatoPersonalizzatoApiActions.updateTemplateDatoPers, (state) => ({...state, isLoading: true})),
  on(DatoPersonalizzatoApiActions.getTemplateDatoPersByIdSuccess, DatoPersonalizzatoApiActions.updateTemplateDatoPersSuccess, (state, { template }) => produce(state, draft => {
    draft.isLoading = false;
    draft.allTemplateDatoPers = draft.allTemplateDatoPers.map(c => {
      if (template.id === c.id) {
        c = template;
      }
      return c;
    });
  })),
  on(DatoPersonalizzatoApiActions.getTemplateDatoPersByIdFailure, DatoPersonalizzatoApiActions.updateTemplateDatoPersFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(DatoPersonalizzatoApiActions.deleteTemplateDatoPers, (state) => ({ ...state, isLoading: true })),
  on(DatoPersonalizzatoApiActions.deleteTemplateDatoPersSuccess, (state, { id }) => ({ ...state, allTemplateDatoPers: state.allTemplateDatoPers.filter(c=>c.id!==id), isLoading: false })),
  on(DatoPersonalizzatoApiActions.deleteTemplateDatoPersFailure, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(DatoPersonalizzatoDefaultActions.clearState, () => ({ ...datiPersonalizzatiInitialState }))
);
