import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { CustomersApiActions } from './customers.action';
import { catchError, exhaustMap, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { OperatorService } from '../../operators/services/operator.service';
import { delayRequest, filterNotNull } from '../../../shared/utility/rxjs-utils';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';
import * as OperatorsSelectors from '../../operators/store/operators.selector';
import { DocumentService } from '../../documents/services/document.service';
import { translate } from '@jsverse/transloco';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { HttpStatus } from '../../../shared/models/state-http-status';
import * as RouterActions from '../../router/store/router.actions';
import { CoreDefaultActions } from '../../store/core.actions';

@Injectable({ providedIn: 'any' })
export class CustomersEffect extends NgRxEffectsBase {

  readonly #customerService = inject(CustomerService);
  readonly #operatorService = inject(OperatorService);
  readonly #documentService = inject(DocumentService);

  public getCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.allCustomers),
    exhaustMap(() => this.#customerService.getAllCustomers().pipe(
      map(customerList => (CustomersApiActions.allCustomersSuccess({ customerList }))),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(CustomersApiActions.allCustomersFailure({ httpStatus: null }))
    ))
  ));

  public getCustomersXloggedOpe$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.customersForLoggedOperator),
    withLatestFrom(this.ngrxUtils.selectLoggedOperatorId()),
    map(([, loggedOpeId]) => loggedOpeId),
    filterNotNull,
    switchMap(loggedOpeId => this.#operatorService.getRoleCustomers(loggedOpeId).pipe(
      map(customerListXLoggedOpe => CustomersApiActions.customersForLoggedOperatorSuccess({ customerListXLoggedOpe })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(CustomersApiActions.customersForLoggedOperatorFailure({ httpStatus: null }))
    ))
  ));

  public getCustomerById$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.getById),
    exhaustMap(({ id }) => this.#customerService.getCustomerById(id).pipe(
      map(customer => (CustomersApiActions.getByIdSuccess({ customer }))),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(CustomersApiActions.allCustomersFailure({ httpStatus: null }))
    ))
  ));

  public createCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.createCustomer),
    withLatestFrom(this.store.select(OperatorsSelectors.loggedOperatorRole).pipe(filterNotNull)),
    switchMap(([{ customer, redirectToDetail, actions }, role]) => delayRequest(this.#customerService.createCustomer(customer)).pipe(
      mergeMap(customer => {
        let extraActions: Action[] = [];
        if(redirectToDetail){
          extraActions.push(RouterActions.go({
            path: ['settings/env-settings/list/edit/', `${customer.id}`],
            extras: { queryParamsHandling: 'merge' }
          }));
        }
        if(actions){
          extraActions = [ ...extraActions, ...actions ];
        }
        return [
          ...extraActions,
          CustomersApiActions.createCustomerSuccess({ customer, addToVisibleCustomers: role.enableNewCustomers })
        ];
      }),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(CustomersApiActions.createCustomerFailure({
        httpStatus: {
          text: translate('errorCreateEnvironment'),
          type: 'error'
        }
      }))
    ))
  ));

  public updateCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.updateCustomer),
    switchMap(({ customer, actions }) => delayRequest(this.#customerService.updateCustomer(customer)).pipe(
      mergeMap(customer => [
        ...(actions ?? []),
        CustomersApiActions.updateCustomerSuccess({ customer })
      ]),
      catchError((e: HttpErrorResponse) => {
        const httpStatus: HttpStatus = {
          type: 'error',
          text: translate('errorUpdateEnvironment')
        };
        const actions: Action[] = [];
        if(e.status === HttpStatusCode.Conflict) {
          httpStatus.text = '';
          const [title, msg1, msg2, msg3] = this.translocoService.translate(['warning', 'dataIsNotUpToDate', 'askLoadUpdatedData', 'allChangesWillBeLost']);
          actions.push(
            CoreDefaultActions.confirmThenDoAction({
              title: `${title}!`,
              msg: `<p class="tw-pb-3">${msg1}. ${msg2}</p><p>${msg3}</p>`,
              actions: [CustomersApiActions.getById({ id: customer.id })]
            })
          );
        }
        return [CustomersApiActions.updateCustomerFailure({ httpStatus }), ...actions ];
      })
    ))
  ));

  public deleteCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.deleteCustomer),
    switchMap(({ id }) => delayRequest(this.#customerService.deleteCustomer(id)).pipe(
      map(() => (CustomersApiActions.deleteCustomerSuccess({ id }))),
      catchError((e: HttpErrorResponse) => {
        let text = translate('errorDeleteEnvironment');
        if(e.status === HttpStatusCode.Forbidden) {
          text = translate('errorDeleteSettingsRelations', { entity: text });
        }
        return of(CustomersApiActions.deleteCustomerFailure({
          httpStatus: {
            type: 'error',
            text
          }
        }));
      })
    ))
  ));

  public uploadLogo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersApiActions.uploadLogo),
    switchMap(({ formData, base64 }) => this.#documentService.uploadDocumentByMainCustomer('Logo', formData, 'PUBLIC').pipe(
      map(() => CustomersApiActions.uploadLogoSuccess({ base64 })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(CustomersApiActions.uploadLogoFailure({
        httpStatus: {
          text: translate('errorUploadLogo'),
          type: 'error'
        }
      }))
    ))
  ));

}
