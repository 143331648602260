import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
import "@bryntum/scheduler-thin/lib/localization/Es.js";
const locale = {
  localeName: "Es",
  localeDesc: "Espa\xF1ol",
  localeCode: "es",
  EventEdit: {
    Calendar: "Calendario",
    "All day": "Todo el d\xEDa",
    day: "D\xEDa",
    week: "Semana",
    month: "Mes",
    year: "A\xF1o",
    decade: "D\xE9cada"
  },
  EventMenu: {
    duplicateEvent: "Duplicar evento",
    copy: "copiar"
  },
  Calendar: {
    toggleSidebar: "Alternar visibilidad de la barra lateral",
    Today: "Hoy",
    Tomorrow: "",
    next: range => `Siguiente ${range}`,
    previous: range => `Anterior ${range}`,
    plusMore: value => `+${value} m\xE1s`,
    allDay: "Todo el d\xEDa",
    endsOn: d => `Termina el ${d}`,
    weekOfYear: ([y, w]) => `Semana ${w}, ${y}`,
    loadFail: "Fallo al cargar los datos del calendario. Contacte con el administrador de su sistema"
  },
  CalendarDrag: {
    holdCtrlForRecurrence: "Presionar CTRL para un evento recurrente"
  },
  CalendarMixin: {
    eventCount: count => `${count || "Sin"} evento${count && count > 1 ? "s" : ""}`
  },
  EventTip: {
    "Edit event": "Editar evento",
    timeFormat: "LST"
  },
  ModeSelector: {
    includeWeekends: "Incluir fines de semana",
    weekends: "Fines de semana"
  },
  AgendaView: {
    Agenda: "Agenda"
  },
  MonthView: {
    Month: "Mes",
    monthUnit: "mes"
  },
  WeekView: {
    weekUnit: "semana"
  },
  YearView: {
    Year: "A\xF1o",
    yearUnit: "a\xF1o",
    noEvents: "No hay eventos"
  },
  EventList: {
    List: "Lista",
    Start: "Inicio",
    Finish: "Finalizaci\xF3n",
    days: count => `${count > 1 ? `${count} ` : ""}d\xEDa${count === 1 ? "" : "s"}`
  },
  DayView: {
    Day: "D\xEDa",
    dayUnit: "d\xEDa",
    daysUnit: "d\xEDas",
    expandAllDayRow: "Expandir secci\xF3n de todo el d\xEDa",
    collapseAllDayRow: "Contraer secci\xF3n de todo el d\xEDa",
    timeFormat: "LST",
    timeAxisTimeFormat: "LST",
    noMatchingDates: "No hay fechas coincidentes"
  },
  DayAgendaView: {
    dayAgenda: "Agenda del d\xEDa"
  },
  DayResourceView: {
    dayResourceView: "Recursos diarios"
  },
  Sidebar: {
    "Filter events": "Filtrar eventos"
  },
  WeekExpander: {
    expandTip: "Hacer clic para expandir fila",
    collapseTip: "Hacer clic para contraer fila"
  }
};
var Es_default = LocaleHelper.publishLocale(locale);
export { Es_default as default };