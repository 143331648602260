import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, signal } from "@angular/core";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { translate, TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { faGrid, faNcIco } from "../../../shared/utility/fontawesome-custom";
import { OverlayPanelComponent } from "../../../shared/components/overlay-panel/overlay-panel.component";
import { MainMenuItem, MainRoutes } from "../../../app.model";
import { RouterLink } from "@angular/router";
import { AvatarModule } from "primeng/avatar";
import { DocumentService } from "../../documents/services/document.service";
import { Store } from "@ngrx/store";
import * as OperatorSelectors from "../../operators/store/operators.selector";
import * as RolesSelectors from "../../role/store/role.selector";
import { WebOperationCode } from "../../role/role.model";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import {
  faBox,
  faBroadcastTower,
  faCalendarAlt,
  faChartLine,
  faCog,
  faFileImport,
  faHeadset,
  faQuestion,
  faSearch,
  faSignOutAlt,
  faTools,
  faUser,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { MenuItem } from "primeng/api";
import { TieredMenuModule } from "primeng/tieredmenu";
import * as AuthActions from "../../auth/store/auth.actions";
import * as RouterActions from "../../router/store/router.actions";
import { getConfigAsync } from "../../settings/config-loader";
import { from, map } from "rxjs";
import { RouterService } from "../../router/service/router.service";
import * as CoreSelectors from "../../store/core.selectors";
import { LanguageCodes } from "../../language/language.model";
import { cloneDeep } from 'lodash-es';
import { Actions, ofType } from '@ngrx/effects';
import { CustomersApiActions } from '../../customers/store/customers.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ta-navbar',
  standalone: true,
  template: `
    <ng-container *transloco="let translate">
      <div class="tw-flex-1 tw-flex tw-gap-2">
        <button class="menu-btn" (click)="menu.instance.toggle($event)">
          <fa-icon [icon]="this.faGrid"></fa-icon>
        </button>
        <span class="tw-hidden md:tw-flex tw-items-center tw-text-gray-600 tw-font-normal tw-text-3xl">
          {{ translate(this.navbarTitle()) }}
        </span>
        <ta-overlay-panel #menu [dismissable]="true" appendTo="body" [hidePopupCaret]="true">
          <div class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
            <div class="tw-grid tw-grid-cols-1 tw-gap-2">
              @for (m of this.menuItems(); track m.text) {
                <a [href]="m.routerLink" class="menu {{m.iconCss}}" [routerLink]="m.routerLink"
                   (click)="$event.preventDefault(); menu.instance.hide();"
                >
                  @if (m.icon) {
                    <div class="icon"><fa-icon [icon]="m.icon"></fa-icon></div>
                  }
                  <span class="tw-text-gray-600">
                    {{ translate("webOperations." + m.text) }}
                  </span>
                </a>
              }
              <!-- Pulsante guida -->
              <div role="presentation" class="menu help" (click)="this.openHelp(); menu.instance.hide()">
                <div class="icon"><fa-icon [icon]="this.faQuestion"></fa-icon></div>
                <span class="tw-text-gray-600">
                  {{ translate("guide") }}
                </span>
              </div>
            </div>
          </div>
        </ta-overlay-panel>
      </div>
      <img [src]="this.logo()" alt="logo" class="tw-h-10" />
      <div class="tw-flex-1 tw-flex tw-justify-end tw-gap-4">
        <p-tieredMenu #avatarMenu [model]="this.avatarMenuItems" [popup]="true" appendTo="body">
          <ng-template pTemplate="item" let-item>
            <div class="tw-flex tw-gap-2 tw-py-2 tw-px-4 hover:tw-cursor-pointer hover:tw-text-primary"
                 role="presentation" (click)="item.command()"
            >
              <fa-icon [icon]="item.icon"></fa-icon>
              <span>{{ translate(item.label) }}</span>
            </div>
          </ng-template>
        </p-tieredMenu>
        <button class="nav-btn tw-text-2xl" [disabled]="!this.canCreateTicket()" (click)="this.createTicket()">
          <fa-icon [icon]="this.faHeadset"></fa-icon>
        </button>
        <p-avatar [label]="this.operatorInitials()" [image]="this.profileImage()" shape="circle"
                  [title]="this.loggedOperator().opeNameSurname" size="large" class="tw-flex hover:tw-cursor-pointer"
                  [style]="{ 'background-color': this.operatorBgColor(), color: this.operatorInitialsColor() }"
                  (click)="avatarMenu.toggle($event)"
        ></p-avatar>
      </div>
    </ng-container>
  `,
  styleUrl: './navbar.component.scss',
  imports: [FaIconComponent, TranslocoDirective, OverlayPanelComponent, RouterLink, AvatarModule, TieredMenuModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {

  @HostBinding('attr.class')
  private readonly cssClass = `tw-flex tw-items-center tw-justify-between tw-p-2 tw-pr-4 tw-mb-2 tw-bg-primary/10 tw-@container`;

  readonly #store = inject(Store);
  readonly #actions$ = inject(Actions);
  readonly #routerService = inject(RouterService);
  readonly #documentService = inject(DocumentService);
  readonly #translocoService = inject(TranslocoService);
  readonly #icons: Partial<{ [key in WebOperationCode]: IconDefinition }> = {
    Assets: faTools,
    Dashboard: faChartLine,
    Settings: faCog,
    Calendar: faCalendarAlt,
    Planner: faUsers,
    Ticket: faHeadset,
    IoT: faBroadcastTower,
    Items: faBox,
    NonCompliance: faNcIco,
    Consultations: faSearch,
    Import: faFileImport
  };
  readonly #menuOrder: WebOperationCode[] = [
    'Assets', 'Calendar', 'Ticket', 'Planner', 'IoT', 'Items', 'NonCompliance', 'Dashboard', 'Consultations',
    'Settings', 'Import'
  ];

  public menuItems = computed<MainMenuItem[]>(() => {
    return cloneDeep(this.#store.selectSignal(RolesSelectors.selectableWebOperations)())
      .map(r => (
        {
          text: r.webOperationCode,
          icon: this.#icons[r.webOperationCode],
          iconCss: r.webOperationCode.toLowerCase(),
          routerLink: this.webOperationCodeToRouterLink(r.webOperationCode)
        }
      ))
      .sort((a, b) => {
        return this.#menuOrder.indexOf(a.text) - this.#menuOrder.indexOf(b.text);
      });
  });
  public loggedOperator = this.#store.selectSignal(OperatorSelectors.loggedOperator);
  public canCreateTicket = this.#store.selectSignal(RolesSelectors.canDoOperation('creation', 'Ticket'));
  public navbarTitle = this.#store.selectSignal(CoreSelectors.navbarTitleKey);
  public profileImage = computed(() => {
    const img = this.#documentService.userProfileImg();
    return !img ? '' : img;
  });
  public operatorInitials = computed(() => {
    const { name, surname } = this.loggedOperator();
    return !this.profileImage() ? (name.charAt(0) + surname.charAt(0)) : '';
  });
  public operatorBgColor = computed(() => {
    return this.profileImage() ? 'transparent' : this.loggedOperator().color;
  });
  public operatorInitialsColor = computed(() => {
    // TODO Da valutare - Se implementiamo questa funzione dobbiamo adeguare anche planner e calendario.
    // getContrastingTextColor(this.loggedOperator().color?.trim() ?? '#fff');
    return '#fff';
  });
  public logo = signal('');
  public avatarMenuItems: MenuItem[] = [
    { label: 'myprofile', icon: faUser as unknown as string, command: this.navigateMyProfile.bind(this) },
    { label: 'disconnect', icon: faSignOutAlt as unknown as string, command: this.logout.bind(this) }
  ];
  public faGrid = faGrid;
  public faHeadset = faHeadset;
  public faQuestion = faQuestion;

  public ngOnInit (): void {
    from(getConfigAsync()).pipe(
      untilDestroyed(this),
      map(c => c?.logoUrl ?? '')
    ).subscribe(logo => {
      this.logo.set(logo);
    });
    this.#actions$.pipe(
      ofType(CustomersApiActions.uploadLogo),
      untilDestroyed(this)
    ).subscribe(({ base64 }) => {
      this.logo.set(base64);
    });
  }

  public navigateMyProfile (): void {
    this.#store.dispatch(RouterActions.go({ path: ['my-profile'], extras: { queryParamsHandling: 'merge' } }));
  }

  public logout (): void {
    this.#store.dispatch(AuthActions.logout());
  }

  public createTicket (): void {
    this.#routerService.directNavigate({
      path: [{ outlets: { modal: ['ticket', 'new'] } }],
      extras: {
        queryParamsHandling: 'merge',
        queryParams: {
          applicant: this.loggedOperator().opeNameSurname
        }
      }
    });
  }

  public openHelp (): void {
    const activeLang = this.#translocoService.getActiveLang() as LanguageCodes;
    const baseUrl = location.origin.includes('localhost') ? 'https://graftest.tagapplication.it/TagSystem' : location.origin;
    const wikiurl = `${baseUrl}/TagSystem/help/wiki/qs/${activeLang}/${translate(`wiki-url.${this.navbarTitle()}`)}`;
    window.open(wikiurl, '_blank', 'height=800,width=450');
  }

  private webOperationCodeToRouterLink (woc: WebOperationCode): MainRoutes {
    let result = woc.toLowerCase() as MainRoutes;
    switch (woc) {
      case 'NonCompliance':
        result = 'non-compliance';
        break;
      case 'IoT':
        result = 'monitoring';
        break;
      case 'Assets':
        result = 'asset';
        break;
      case 'Consultations':
        result = 'consultations';
        break;
    }
    return result;
  }
}
