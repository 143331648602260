import { OperatorDTO, OperatorDTOExtended } from '../operators.model';
import { createReducer, on } from '@ngrx/store';
import * as OperatorsActions from './operators.actions';
import { cloneDeep } from 'lodash-es';
import { OperatorSettingsDTO } from '../operator-other-settings.model';
import { produce } from 'immer';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { Nullable } from '../../../shared/models/types';
import { UsersApiActions } from '../../../features/settings/store/actions/users.actions';
import { ArrayUtils } from '../../../shared/utility/ArrayUtils';

export interface OperatorsState {
  allOperators: OperatorDTO[]
  allOperatorsMapId: Map<number, OperatorDTO>
  allOperatorsMapUsername: Map<string, OperatorDTO>
  operatorsForOperator: OperatorDTO[]
  loggedOperator: OperatorDTOExtended | null
  defaultOperatorSettings: OperatorSettingsDTO | null
  isLoading: boolean
  isLoadingOperatorSettings: boolean
  httpStatus: Nullable<HttpStatus>
}

export const operatorsInitialState: OperatorsState = {
  allOperators: [],
  allOperatorsMapId: new Map(),
  allOperatorsMapUsername: new Map(),
  operatorsForOperator: [],
  loggedOperator: null,
  defaultOperatorSettings: null,
  isLoading: false,
  isLoadingOperatorSettings: false,
  httpStatus: null
};

const buildOperatorMapId = (arr: OperatorDTO[]): Map<number, OperatorDTO> => {
  return arr.reduce((map, o) => {
    return map.set(o.id, o);
  }, new Map<number, OperatorDTO>());
};

const buildOperatorMapUsername = (arr: OperatorDTO[]): Map<string, OperatorDTO> => {
  return arr.reduce((map, o) => {
    return map.set(o.username, o);
  }, new Map<string, OperatorDTO>());
};

export const operatorsReducer = createReducer(
  operatorsInitialState,

  on(OperatorsActions.retrieveAll, (state) => ({ ...state, isLoading: true })),
  on(OperatorsActions.retrieveAllSuccess, (state, { allOperators }) => ({
    ...state,
    allOperators,
    allOperatorsMapId: buildOperatorMapId(allOperators),
    allOperatorsMapUsername: buildOperatorMapUsername(allOperators),
    isLoading: false
  })),
  on(OperatorsActions.retrieveAllFailure, (state) => ({ ...state, allOperators: cloneDeep(state.allOperators), isLoading: false })),

  on(UsersApiActions.createUserSuccess, (state, { user }) => produce(state, draft => {
    if(!user.systemOperator) {
      const u = cloneDeep(user);
      delete u.functions;
      draft.allOperators.push(u);
      draft.allOperatorsMapId = buildOperatorMapId(draft.allOperators);
      draft.allOperatorsMapUsername = buildOperatorMapUsername(draft.allOperators);
    }
  })),
  on(UsersApiActions.updateUserSuccess, UsersApiActions.updateUserSuccess, (state, { user }) => produce(state, draft => {
    if(!user.systemOperator) {
      const u = cloneDeep(user);
      delete u.functions;
      draft.allOperators = ArrayUtils.updateArrayByObjProp(draft.allOperators, 'id', user);
      draft.operatorsForOperator = ArrayUtils.updateArrayByObjProp(draft.operatorsForOperator, 'id', user);
      draft.allOperatorsMapId = buildOperatorMapId(draft.allOperators);
      draft.allOperatorsMapUsername = buildOperatorMapUsername(draft.allOperators);
    }
  })),
  on(UsersApiActions.deleteUserSuccess, (state, { id }) => produce(state, draft => {
    draft.allOperators = draft.allOperators.filter(o => o.id !== id);
    draft.operatorsForOperator = draft.operatorsForOperator.filter(o => o.id !== id);
    draft.allOperatorsMapId = buildOperatorMapId(draft.allOperators);
    draft.allOperatorsMapUsername = buildOperatorMapUsername(draft.allOperators);
  })),

  on(OperatorsActions.operatorsForOperatorSuccess, (state, { operators }) => ({ ...state, operatorsForOperator: operators, isLoading: false })),
  on(OperatorsActions.operatorsForOperatorFailure, (state) => ({ ...state, operatorsForOperator: [], isLoading: false })),

  on(OperatorsActions.defaultOperatorSettingsSuccess, (state, { settings }) => ({ ...state, defaultOperatorSettings: settings })),

  on(
    OperatorsActions.updateOperatorSettings, OperatorsActions.updateJobPreferences, OperatorsActions.updateTicketPreferences, OperatorsActions.updateDevicePreferences, OperatorsActions.updateMonitoringPreferences, OperatorsActions.updateCalendarPreferences,
    (state) => produce(state, draft => { draft.isLoadingOperatorSettings = true; })
  ),
  on(OperatorsActions.updateOperatorSettingsSuccess, OperatorsActions.updateJobPreferencesSuccess, OperatorsActions.updateTicketPreferencesSuccess, OperatorsActions.updateDevicePreferencesSuccess, OperatorsActions.updateMonitoringPreferencesSuccess, OperatorsActions.updateCalendarPreferencesSuccess,
    (state, { settings }) => produce(state, draft => {
      if(draft.loggedOperator) {
        draft.loggedOperator.operatorSettings = settings;
      }
      draft.isLoadingOperatorSettings = false;
    })
  ),
  on(
    OperatorsActions.updateOperatorSettingsFailure, OperatorsActions.updateJobPreferencesFailure, OperatorsActions.updateTicketPreferencesFailure, OperatorsActions.updateDevicePreferencesFailure, OperatorsActions.updateMonitoringPreferencesFailure, OperatorsActions.updateCalendarPreferencesFailure,
    (state, { httpStatus }) => produce(state, draft => { draft.isLoadingOperatorSettings = false; draft.httpStatus = httpStatus; })
  ),

  on(OperatorsActions.updateDashboardUserFavourite, (state) => ({ ...state, isLoading: true })),
  on(OperatorsActions.updateDashboardUserFavouriteSuccess, (state, { settings }) => ({
    ...state,
    loggedOperator: Object.assign({}, state.loggedOperator, { operatorSettings: settings }),
    isLoading: false
  })),
  on(OperatorsActions.updateDashboardUserFavouriteFailure, (state) => ({ ...state, allOperators: cloneDeep(state.allOperators), isLoading: false })),

  on(OperatorsActions.updatePlannerPreferences, (state) => ({ ...state, isLoading: true })),
  on(OperatorsActions.updatePlannerPreferencesSuccess, (state, { body }) => {
    let result = state;
    if(state.loggedOperator && state.loggedOperator.operatorSettings) {
      result = {
        ...state,
        loggedOperator: {
          ...state.loggedOperator,
          operatorSettings: {
            ...state.loggedOperator.operatorSettings,
            orderedPlanner: JSON.stringify(body)
          }
        },
        isLoading: false
      };
    }
    return result;
  }),
  on(OperatorsActions.updatePlannerPreferencesFailure, (state) => ({ ...state, allOperators: cloneDeep(state.allOperators), isLoading: false })),

  on(OperatorsActions.updateUserEmailSuccess, (state, { email }) => ({ ...state, loggedOperator: Object.assign({}, state.loggedOperator, { email }) })),
  on(OperatorsActions.updateUserEmailFailure, (state) => ({ ...state, loggedOperator: Object.assign({}, state.loggedOperator) })),

  on(OperatorsActions.updateProfileImageSuccess, (state, { img }) => ({ ...state, loggedOperator: Object.assign({}, state.loggedOperator, { photoLink: img.id }) })),

  on(OperatorsActions.updateOperatorSignatureSuccess, (state, { img }) => ({ ...state, loggedOperator: Object.assign({}, state.loggedOperator, { signature: img.path }) })),

  on(OperatorsActions.setLoggedOperator, (state, { loggedOperator }) => ({ ...state, loggedOperator })),

  on(OperatorsActions.clearErrors, (state) => ({ ...state, httpStatus: null })),
  on(OperatorsActions.clearState, () => ({ ...operatorsInitialState }))
);
