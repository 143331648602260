import { SettingsDocumentDTO, SettingsTaskDTO, SettingsTicketDTO } from '../settings.model';
import { Nullable } from '../../../shared/models/types';
import { HttpStatus } from '../../../shared/models/state-http-status';
import { createReducer, on } from '@ngrx/store';
import { SettingsCoreAction, SettingsDefaultAction } from './settings-core.action';
import { cloneDeep } from 'lodash-es';

export interface SettingsCoreState {
	documentsSettings: SettingsDocumentDTO
	tasksSettings: SettingsTaskDTO
	ticketSettings: SettingsTicketDTO
	isLoading: boolean
	httpStatus: Nullable<HttpStatus>
}

export const settingsCoreState: SettingsCoreState = {
  documentsSettings: { visibility: 'PUBLIC' },
  tasksSettings: { visibility: 'PUBLIC', actionNFC: 'OnlyOpening' },
  ticketSettings: { inheritResponsibleAsCollaborator: false },
  isLoading: false,
  httpStatus: null
};

const isLoadingActions = [
  SettingsCoreAction.getTasksSettings, SettingsCoreAction.getDocumentsSettings, SettingsCoreAction.updateTasksSettings,
  SettingsCoreAction.updateDocumentsSettings, SettingsCoreAction.getTicketSettings, SettingsCoreAction.updateTicketSettings
];

const failureActions = [
  SettingsCoreAction.getTasksSettingsFailure, SettingsCoreAction.updateTasksSettingsFailure,
  SettingsCoreAction.getDocumentsSettingsFailure, SettingsCoreAction.updateDocumentsSettingsFailure,
  SettingsCoreAction.getTicketSettingsFailure, SettingsCoreAction.updateTicketSettingsFailure
];

export const settingsCoreReducer = createReducer(
  settingsCoreState,

  on(...isLoadingActions, (state) => ({ ...state, isLoading: true })),

  on(SettingsCoreAction.getDocumentsSettingsSuccess, SettingsCoreAction.updateDocumentsSettingsSuccess,
    (state, { settings }) => ({ ...state, documentsSettings: { ...settings }, isLoading: false })
  ),

  on(SettingsCoreAction.getTasksSettingsSuccess, SettingsCoreAction.updateTasksSettingsSuccess,
    (state, { settings }) => ({ ...state, tasksSettings: { ...settings }, isLoading: false })
  ),

  on(SettingsCoreAction.getTicketSettingsSuccess, SettingsCoreAction.updateTicketSettingsSuccess,
    (state, { settings }) => ({ ...state, ticketSettings: { ...settings }, isLoading: false })
  ),

  on(...failureActions, (state, { httpStatus }) => ({ ...state, httpStatus: cloneDeep(httpStatus), isLoading: false })),

  on(SettingsDefaultAction.clearErrors, (state)  => ({ ...state, httpStatus: null })),
  on(SettingsDefaultAction.clearState, () => ({ ...settingsCoreState }))
);