import { createReducer, on } from '@ngrx/store';
import * as RouterActions from '../store/router.actions';

export interface RouterExtraState {
  embedded: boolean
}

const routerExtraInitialState: RouterExtraState = {
  embedded: false
};

export const routerExtraReducer = createReducer(
  routerExtraInitialState,

  on(RouterActions.setEmbedded, (state, { embedded }) => ({ ...state, embedded }))
);
