import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
import "@bryntum/engine-thin/lib/localization/It.js";
import "@bryntum/scheduler-thin/lib/localization/It.js";
const locale = {
  localeName: "It",
  localeDesc: "Italiano",
  localeCode: "it",
  ConstraintTypePicker: {
    none: "Nessuno",
    assoonaspossible: "Il pi\xF9 presto possibile",
    aslateaspossible: "Il pi\xF9 tardi possibile",
    muststarton: "Deve iniziare il",
    mustfinishon: "Deve finire il",
    startnoearlierthan: "Inizio non prima di",
    startnolaterthan: "Inizio non oltre",
    finishnoearlierthan: "Fine non prima di",
    finishnolaterthan: "Fine non oltre"
  },
  SchedulingDirectionPicker: {
    Forward: "Avanti",
    Backward: "Indietro",
    inheritedFrom: "Ereditato da",
    enforcedBy: "Impossato da"
  },
  CalendarField: {
    "Default calendar": "Calendario predefinito",
    editCalendar: "Modifica il calendario"
  },
  TaskEditorBase: {
    Information: "Informazioni",
    Save: "Salva",
    Cancel: "Annulla",
    Delete: "Elimina",
    calculateMask: "Calcolo...",
    saveError: "Impossibile salvare, correggi prima gli errori",
    repeatingInfo: "Visualizzare un evento ripetitivo",
    editRepeating: "Modifica"
  },
  TaskEdit: {
    editEvent: "Modifica evento",
    ConfirmDeletionTitle: "Conferma eliminazione",
    ConfirmDeletionMessage: "Vuoi davvero eliminare l\u2019evento?"
  },
  GanttTaskEditor: {
    editorWidth: "44em"
  },
  SchedulerTaskEditor: {
    editorWidth: "32em"
  },
  SchedulerGeneralTab: {
    labelWidth: "6em",
    General: "Generale",
    Name: "Nome",
    Resources: "Risorse",
    "% complete": "% completato",
    Duration: "Durata",
    Start: "Inizio",
    Finish: "Fine",
    Effort: "Sforzo",
    Preamble: "Preambolo",
    Postamble: "Postfazione"
  },
  GeneralTab: {
    labelWidth: "6.5em",
    General: "Generale",
    Name: "Nome",
    "% complete": "% completato",
    Duration: "Durata",
    Start: "Inizio",
    Finish: "Fine",
    Effort: "Sforzo",
    Dates: "Date"
  },
  SchedulerAdvancedTab: {
    labelWidth: "13em",
    Advanced: "Avanzato",
    Calendar: "Calendario",
    "Scheduling mode": "Modalit\xE0 di programmazione",
    "Effort driven": "Basato sullo sforzo",
    "Manually scheduled": "Programmato manualmente",
    "Constraint type": "Tipo di vincolo",
    "Constraint date": "Data di vincolo",
    Inactive: "Disattivo",
    "Ignore resource calendar": "Ignora il calendario delle risorse"
  },
  CalendarEditorDatePicker: {
    addException: "Aggiungere eccezione",
    addWeek: "Aggiungi settimana"
  },
  CalendarEditorExceptionTab: {
    addException: "Aggiungere eccezione",
    removeException: "Rimuovere l'eccezione",
    noRows: "Nessuna eccezione aggiunta"
  },
  CalendarEditorWeekTab: {
    addWeek: "Aggiungi settimana",
    removeWeek: "Rimuovere la settimana"
  },
  CalendarEditor: {
    daysAreWorkingByDefault: "I giorni funzionano per impostazione predefinita",
    workingTimeCalendar: "Calendario dell'orario di lavoro",
    exceptions: "Eccezioni",
    general: "Generale",
    name: "Nome",
    parent: "Genitore",
    save: "Salva",
    weeks: "Settimane",
    error: "Errore",
    inputErrors: "Ci sono errori di input. Si prega di correggere prima di salvare le modifiche.",
    delete: "Eliminare",
    addCalendar: "Aggiungi un calendario",
    confirmation: "Conferma",
    removeCalendarWithChildren: 'Il calendario ha calendari infantili. Fai clic su "S\xEC" per rimuovere il calendario con i suoi figli.',
    removeCalendarAndLinks: 'Il calendario \xE8 utilizzato da alcuni record. Fai clic su "S\xEC" per sbloccarli e rimuovere il calendario.',
    newException: "Nuova eccezione",
    newWeek: "Nuova settimana"
  },
  CalendarEditorDateInfo: {
    isWorking: " sta lavorando",
    isNotWorking: " non funziona",
    basedOn: " basato su:",
    byDefault: "Per impostazione predefinita",
    rangeTpl: (start, end) => `Dalle ${start} alle ${end}`
  },
  CalendarEditorLegend: {
    workingDay: "Giorno lavorativo",
    nonWorkingDay: "Giorno non di lavoro"
  },
  AvailabilityRangeError: {
    errorOverlap: "Ci sono gamme sovrapposte",
    errorMissingDate: "Sono richiesti l'ora di inizio e fine",
    errorStartAfterEnd: "L'ora di inizio non pu\xF2 essere dopo l'ora di fine"
  },
  CalendarEditorExceptionPanel: {
    name: "Nome",
    from: "Da",
    to: "A",
    is: "\xC8",
    nonWorking: "Non funzionante",
    working: "Lavorando",
    hours: "Ore",
    repeat: "Ripetere",
    repeatEnd: "Ripeti fine",
    errorMissingDate: "\xC8 richiesto l'intervallo della data di eccezione",
    errorStartAfterEnd: '"From" date cannot be after the "To" date ',
    errorStartAndEndRepeatNumberMismatch: "L'eccezione dovrebbe iniziare e terminare lo stesso numero di volte"
  },
  CalendarEditorWeekPanel: {
    name: "Nome",
    from: "Da",
    to: "A",
    days: "Giorni",
    copyDay: "Copia giorno",
    pasteDay: "Giorno in pasta",
    stopCopying: "Smettila di copiare"
  },
  CalendarEditorAvailabilityRangeContainer: {
    addRange: "Aggiungi gamma",
    removeRange: "Rimuovere la gamma"
  },
  CalendarEditorWeekGrid: {
    errorStartAfterEnd: '"From" date cannot be after the "To" date ',
    errorNoDefaultWeek: "\xC8 richiesta la configurazione della settimana",
    errorMultipleDefaultWeeks: "\xC8 specificata pi\xF9 di una settimana predefinita",
    errorNoWeekAvailability: "La settimana deve fornire un po 'di tempo di lavoro",
    errorInvalidWeekAvailability: "La settimana ha errori di configurazione dell'orario di lavoro",
    noRows: "Nessuna settimana aggiunta"
  },
  AdvancedTab: {
    labelWidth: "11.5em",
    Advanced: "Avanzato",
    Calendar: "Calendario",
    "Scheduling mode": "Modalit\xE0 di programmazione",
    "Effort driven": "Basato sullo sforzo",
    "Manually scheduled": "Programmato manualmente",
    "Constraint type": "Tipo di vincolo",
    "Constraint date": "Data di vincolo",
    Constraint: "Vincolo",
    Rollup: "Rollup",
    Inactive: "Disattivo",
    "Ignore resource calendar": "Ignora il calendario delle risorse",
    "Scheduling direction": "Direzione della pianificazione",
    projectBorder: "Bordo del progetto",
    ignore: "Ignora",
    honor: "Rispetta",
    askUser: "Chiedi all'utente"
  },
  DependencyTab: {
    Predecessors: "Precedenti",
    Successors: "Successivi",
    ID: "ID",
    Name: "Nome",
    Type: "Tipo",
    Lag: "Ritardo",
    cyclicDependency: "Dipendenza ciclica",
    invalidDependency: "Dipendenza non valida"
  },
  NotesTab: {
    Notes: "Note"
  },
  ResourceCalendarColumn: {
    calendar: "Calendario"
  },
  ResourcesTab: {
    unitsTpl: ({
      value
    }) => `${value}%`,
    Resources: "Risorse",
    Resource: "Risorsa",
    Units: "Unit\xE0"
  },
  RecurrenceTab: {
    title: "Ripeti"
  },
  SchedulingModePicker: {
    Normal: "Normale",
    "Fixed Duration": "Durata fissa",
    "Fixed Units": "Unit\xE0 fisse",
    "Fixed Effort": "Sforzo fisso"
  },
  ResourceHistogram: {
    barTipInRange: '<b>{resource}</b> {startDate} - {endDate}<br><span class="{cls}">{allocated} di {available}</span> assegnato',
    barTipOnDate: '<b>{resource}</b> on {startDate}<br><span class="{cls}">{allocated} di {available}</span> assegnato',
    groupBarTipAssignment: '<b>{resource}</b> - <span class="{cls}">{allocated} di {available}</span>',
    groupBarTipInRange: '{startDate} - {endDate}<br><span class="{cls}">{allocated} di {available}</span> allocated:<br>{assignments}',
    groupBarTipOnDate: 'Il {startDate}<br><span class="{cls}">{allocated} di {available}</span> assegnato:<br>{assignments}',
    plusMore: "altre +{value}"
  },
  ResourceUtilization: {
    barTipInRange: '<b>{event}</b> {startDate} - {endDate}<br><span class="{cls}">{allocated}</span> assegnato',
    barTipOnDate: '<b>{event}</b> il {startDate}<br><span class="{cls}">{allocated}</span> assegnato',
    groupBarTipAssignment: '<b>{event}</b> - <span class="{cls}">{allocated}</span>',
    groupBarTipInRange: '{startDate} - {endDate}<br><span class="{cls}">{allocated} di {available}</span> assegnato:<br>{assignments}',
    groupBarTipOnDate: 'Il {startDate}<br><span class="{cls}">{allocated} di {available}</span> assegnato:<br>{assignments}',
    plusMore: "altre +{value}",
    nameColumnText: "Risorsa / Evento"
  },
  SchedulingIssueResolutionPopup: {
    "Cancel changes": "Annulla la modifica e non fare nulla",
    schedulingConflict: "Conflitto di programmazione",
    emptyCalendar: "Errore di configurazione calendario",
    cycle: "Ciclo di programmazione",
    Apply: "Applica"
  },
  CycleResolutionPopup: {
    dependencyLabel: "Seleziona una dipendenza:",
    invalidDependencyLabel: "Ci sono dipendenze non valide da risolvere:"
  },
  DependencyEdit: {
    Active: "Attiva"
  },
  SchedulerProBase: {
    propagating: "Calcolo progetto",
    storePopulation: "Caricamento dati",
    finalizing: "Finalizzazione risultati"
  },
  EventSegments: {
    splitEvent: "Dividi evento",
    renameSegment: "Rinomina"
  },
  NestedEvents: {
    deNestingNotAllowed: "Denidificazione non consentita",
    nestingNotAllowed: "Nidificazione non consentita"
  },
  VersionGrid: {
    compare: "Confronta",
    description: "Descrizione",
    occurredAt: "Si \xE8 verificato il",
    rename: "Rinomina",
    restore: "Ripristina",
    stopComparing: "Interrompi confronto"
  },
  Versions: {
    entityNames: {
      TaskModel: "compito",
      AssignmentModel: "assegnazione",
      DependencyModel: "link",
      ProjectModel: "progetto",
      ResourceModel: "risorsa",
      other: "oggetto"
    },
    entityNamesPlural: {
      TaskModel: "compiti",
      AssignmentModel: "assegnazioni",
      DependencyModel: "link",
      ProjectModel: "progetti",
      ResourceModel: "risorse",
      other: "oggetti"
    },
    transactionDescriptions: {
      update: "Modificate {n} {entities}",
      add: "Aggiunte {n} {entities}",
      remove: "Rimosse {n} {entities}",
      move: "Spostate {n} {entities}",
      mixed: "Modificate {n} {entities}"
    },
    addEntity: "Aggiunto {type} **{name}**",
    removeEntity: "Rimosso {type} **{name}**",
    updateEntity: "Modificato {type} **{name}**",
    moveEntity: "Spostato {type} **{name}** da {from} a {to}",
    addDependency: "Aggiunto link da **{from}** a **{to}**",
    removeDependency: "Rimosso link da **{from}** a **{to}**",
    updateDependency: "Modificato link da **{from}** a **{to}**",
    addAssignment: "Assegnato **{resource}** a **{event}**",
    removeAssignment: "Rimossa assegnazione di **{resource}** da **{event}**",
    updateAssignment: "Modificata assegnazione di **{resource}** a **{event}**",
    noChanges: "Nessuna modifica",
    nullValue: "nessuno",
    versionDateFormat: "M/D/YYYY h:mm a",
    undid: "Modifiche annullate",
    redid: "Modifiche ripristinate",
    editedTask: "Propriet\xE0 compito modificate",
    deletedTask: "Compito eliminato",
    movedTask: "Compito spostato",
    movedTasks: "Compiti spostati"
  }
};
var It_default = LocaleHelper.publishLocale(locale);
export { It_default as default };