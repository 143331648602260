import { createAction, props } from '@ngrx/store';
import { DashboardDTO } from '../dashboard.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';

/* DEFAULT ACTIONS */
export const clearState = createAction('[Dashboard] clear state');

/* API ACTIONS */
export const getAllByOpeId = createAction('[Dashboard/API] get all by user id');
export const getAllByOpeIdSuccess = createAction('[Dashboard/API] get all by user id success', props<{ dashboardList: DashboardDTO[] }>());
export const getAllByOpeIdFailure = NgRxUtils.createHttpStatusAction('[Dashboard/API] get all by user id failure');
