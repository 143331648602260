import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { TicketCategoriesService } from '../services/ticket-categories.service';
import * as TicketCategoriesAction from './ticket-categories.action';
import { map, switchMap } from 'rxjs';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class TicketCategoriesEffect extends NgRxEffectsBase {
  private readonly ticketCategoriesService = inject(TicketCategoriesService);

  public getAll$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCategoriesAction.retrieveAll),
    switchMap(() => this.ticketCategoriesService.getAllTicketCategories().pipe(
      map(categories => TicketCategoriesAction.retrieveAllSucces({ categories })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(TicketCategoriesAction.retrieveAllFailure({ httpStatus: null }))
    ))
  ));
}
