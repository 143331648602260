import LocaleHelper from "@bryntum/core-thin/lib/localization/LocaleHelper.js";
const locale = {
  localeName: "Es",
  localeDesc: "Espa\xF1ol",
  localeCode: "es",
  RemoveDependencyCycleEffectResolution: {
    descriptionTpl: "Eliminar dependencia"
  },
  DeactivateDependencyCycleEffectResolution: {
    descriptionTpl: "Desactivar dependencia"
  },
  CycleEffectDescription: {
    descriptionTpl: "Se ha encontrado un ciclo formado por: {0}"
  },
  EmptyCalendarEffectDescription: {
    descriptionTpl: 'La tarea "{2}" no se puede realizar. El calendario "{0}" no proporciona ning\xFAn intervalo de tiempo de trabajo.',
    forwardDescriptionTpl: 'La tarea "{2}" no se puede realizar. El calendario "{0}" no tiene tiempo de trabajo despu\xE9s de {1}.',
    backwardDescriptionTpl: 'La tarea "{2}" no se puede realizar. El calendario "{0}" no tiene tiempo de trabajo antes {1}.',
    noIntersectionDescriptionTpl: 'La tarea "{2}" no se puede realizar. Su calendario "{0}" y sus calendarios de recursos no tienen un tiempo de trabajo com\xFAn.'
  },
  Use24hrsEmptyCalendarEffectResolution: {
    descriptionTpl: "Use un calendario de 24 con los s\xE1bados y dominngos no lectivos."
  },
  Use8hrsEmptyCalendarEffectResolution: {
    descriptionTpl: "Use un calendario de 8 horas (08:00-12:00, 13:00-17:00) con s\xE1bados y domingos no lectivos."
  },
  IgnoreProjectConstraintResolution: {
    descriptionTpl: "Ignore la frontera del proyecto y contin\xFAe con el cambio."
  },
  ProjectConstraintConflictEffectDescription: {
    startDescriptionTpl: 'Moviste la tarea "{0}" para comenzar {1}. Esto es antes de la fecha de inicio del proyecto {2}.',
    endDescriptionTpl: 'Movi\xF3 la tarea "{0}" para finalizar {1}. Esto es despu\xE9s de la fecha de finalizaci\xF3n del proyecto {2}.'
  },
  HonorProjectConstraintResolution: {
    descriptionTpl: "Ajuste la tarea para honrar la frontera del proyecto."
  },
  IgnoreResourceCalendarEmptyCalendarEffectResolution: {
    descriptionTpl: "Ignore los calendarios de recursos y programen la tarea solo con su propio calendario."
  },
  ConflictEffectDescription: {
    descriptionTpl: "Se ha detectado un conflicto de programaci\xF3n: {0} est\xE1 en conflicto con {1}"
  },
  ConstraintIntervalDescription: {
    dateFormat: "LLL"
  },
  ProjectConstraintIntervalDescription: {
    startDateDescriptionTpl: "Fecha de inicio del proyecto {0}",
    endDateDescriptionTpl: "Fecha de finalizaci\xF3n del proyecto {0}"
  },
  DependencyType: {
    long: ["De inicio a inicio", "De inicio a finalizaci\xF3n", "De finalizaci\xF3n a inicio", "De finalizaci\xF3n a finalizaci\xF3n"]
  },
  ManuallyScheduledParentConstraintIntervalDescription: {
    startDescriptionTpl: '"{2}" programado manualmente fuerza a sus dependientes a no empezar antes de {0}',
    endDescriptionTpl: '"{2}" programado manualmente fuerza a sus dependientes a no empezar antes de {1}'
  },
  DisableManuallyScheduledConflictResolution: {
    descriptionTpl: 'Desactivar programaci\xF3n manual para "{0}"'
  },
  DependencyConstraintIntervalDescription: {
    descriptionTpl: 'Dependencia ({2}) desde "{3}" hasta "{4}"'
  },
  RemoveDependencyResolution: {
    descriptionTpl: 'Eliminar dependencia de "{1}" a "{2}"'
  },
  DeactivateDependencyResolution: {
    descriptionTpl: 'Desactivar dependencia desde "{1}" hasta "{2}"'
  },
  DateConstraintIntervalDescription: {
    startDateDescriptionTpl: 'Limitaci\xF3n de tareas "{2}" {3} {0}',
    endDateDescriptionTpl: 'Limitaci\xF3n de tareas "{2}" {3} {1}',
    constraintTypeTpl: {
      startnoearlierthan: "Empezar no antes del",
      finishnoearlierthan: "Terminar no antes del",
      muststarton: "Debe empezar el",
      mustfinishon: "Debe terminar el",
      startnolaterthan: "Empezar no despu\xE9s del",
      finishnolaterthan: "Terminar no despu\xE9s del"
    }
  },
  RemoveDateConstraintConflictResolution: {
    descriptionTpl: 'Eliminar limitaci\xF3n "{1}" de la tarea "{0}"'
  },
  PostponeDateConstraintConflictResolution: {
    descriptionTpl: 'Posponer la resoluci\xF3n y marcar la tarea "{0}" con marcador de conflicto'
  }
};
var Es_default = LocaleHelper.publishLocale(locale);
export { Es_default as default };