import { LoadingFilterDataSource, WidgetDictionary, WidgetFilterDataSource } from '../widget.model';
import { createReducer, on } from '@ngrx/store';
import { WidgetApiActions, WidgetDefaultActions } from './widget.actions';
import { produce } from 'immer';
import { Nullable } from '../../../shared/models/types';
import { HttpStatus } from '../../../shared/models/state-http-status';

export interface WidgetState {
  // Dati per i widget di tipo filtro
  filterDataSource: WidgetFilterDataSource
  // Dati per i widget di tipo filtro
  isLoadingFilterDataSource: LoadingFilterDataSource
  widgets: WidgetDictionary
  httpStatus: Nullable<HttpStatus>
}

const initialState: WidgetState = {
  filterDataSource: {},
  isLoadingFilterDataSource: {},
  widgets: {},
  httpStatus: null
};

export const widgetReducer = createReducer(
  initialState,

  on(WidgetApiActions.getByModuleSuccess, (state, { widgets, module }) => produce(state, newState => {
    newState.widgets[module] = widgets;
  })),

  on(WidgetApiActions.getFilterDataSourceByWidget, (state, { widget }) => produce(state, newState => {
    newState.isLoadingFilterDataSource[widget.entityType] = true;
  })),
  on(WidgetApiActions.getFilterDataSourceByWidgetSuccess, (state, { dataSource, entityType }) => produce(state, newState => {
    newState.filterDataSource[entityType] = dataSource;
    newState.isLoadingFilterDataSource[entityType] = false;
  })),
  on(WidgetApiActions.getFilterDataSourceByWidgetFailure, (state, { httpStatus }) => produce(state, newState => {
    newState.httpStatus = httpStatus;
  })),

  on(WidgetDefaultActions.clearHttpStatus, () => ({ ...initialState, httpStatus: null }))
);
